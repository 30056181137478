// ListVentes.js
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Container, Form, Row, Col, FloatingLabel, Pagination, Table } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';
import Header from '../Header';
import '../styles/ListVentes.css';

const ListVentes = () => {
  const { user } = useContext(SycadContext);
  const [ventes, setVentes] = useState([]);
  const [filteredVentes, setFilteredVentes] = useState([]);
  const [localites, setLocalites] = useState([]);
  const [petroliers, setPetroliers] = useState([]);
  const [stations, setStations] = useState([]);
  const [selectedLocalite, setSelectedLocalite] = useState('0');
  const [selectedPetrolier, setSelectedPetrolier] = useState('0');
  const [selectedStation, setSelectedStation] = useState('');
  const [matriculeFilter, setMatriculeFilter] = useState('');
  const [dateDebut, setDateDebut] = useState('');
  const [dateFin, setDateFin] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const ventesPerPage = 200;

  useEffect(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];

    setDateDebut(firstDayOfMonth);
    setDateFin(lastDayOfMonth);
  }, []);

  const fetchVentes = useCallback(async () => {
    const dateDebutFormatted = dateDebut.replace(/-/g, '');
    const dateFinFormatted = dateFin.replace(/-/g, '');

    let query = `SELECT * FROM v_vente v 
      WHERE v.date_mouv BETWEEN '${dateDebutFormatted}' AND '${dateFinFormatted}'
      AND (${matriculeFilter ? `'${matriculeFilter}'` : 'NULL'} IS NULL OR v.matricule = '${matriculeFilter}')
      AND (${selectedStation ? `'${selectedStation}'` : 'NULL'} IS NULL OR v.code_station = '${selectedStation}')
      AND (${selectedPetrolier || 0} = 0 OR v.id_petrolier = ${selectedPetrolier})
      AND (${selectedLocalite || 0} = 0 OR v.id_localite = ${selectedLocalite})
    `;
    if (user.code_groupe === 'STATION') {
        query += ` AND v.id_station = ${user.id_sgroupe}`;
      }
     query += ` ORDER BY date_mouv DESC`;
  
    console.log('Fetching ventes with query:', query);

    try {
      const response = await envoyerRequeteApi(query, '');
      setVentes(response.datas);
      setFilteredVentes(response.datas);
      console.log('Fetched ventes:', response.datas);
    } catch (error) {
      console.error('Error fetching ventes', error);
    }
  }, [user,dateDebut, dateFin, matriculeFilter, selectedStation, selectedPetrolier, selectedLocalite]);

  const fetchLocalites = useCallback(async () => {
    const query = `select id_localite, btrim(concat(nom_departement,': ',nom_localite)) as nom_localite from liste_localites ll order by nom_departement `;
    console.log('Fetching localites with query:', query);

    try {
      const response = await envoyerRequeteApi(query, '');
      setLocalites(response.datas);
      console.log('Fetched localites:', response.datas);
    } catch (error) {
      console.error('Error fetching localites', error);
    }
  }, []);

  const fetchPetroliers = useCallback(async () => {
    const query = `SELECT id_petrolier, btrim(nom_petrolier) as nom_petrolier FROM liste_petroliers lp ORDER BY nom_petrolier`;
    console.log('Fetching petroliers with query:', query);

    try {
      const response = await envoyerRequeteApi(query, '');
      setPetroliers(response.datas);
      console.log('Fetched petroliers:', response.datas);
    } catch (error) {
      console.error('Error fetching petroliers', error);
    }
  }, []);

  const fetchStations = useCallback(async () => {
    let query = ` SELECT id_station ,btrim( concat(ls.nom_station , '_' ,nom_departement, ': ', nom_localite)) as nom_station    
      FROM liste_station ls order by ls.nom_station,nom_departement `;
      
    console.log('Fetching stations with query:', query);

    try {
      const response = await envoyerRequeteApi(query, '');
      setStations(response.datas);
      console.log('Fetched stations:', response.datas);
    } catch (error) {
      console.error('Error fetching stations', error);
    }
  }, []);

  useEffect(() => {
    if (dateDebut && dateFin) {
      fetchVentes();
    }
    fetchLocalites();
    fetchPetroliers();
    fetchStations();
  }, [dateDebut, dateFin, fetchVentes, fetchLocalites, fetchPetroliers, fetchStations]);

  const applyFilters = useCallback(() => {
    let filtered = ventes;

    if (selectedLocalite && selectedLocalite !== '0') {
      filtered = filtered.filter(vente => vente.id_localite === parseInt(selectedLocalite));
    }
    if (selectedPetrolier && selectedPetrolier !== '0') {
      filtered = filtered.filter(vente => vente.id_petrolier === parseInt(selectedPetrolier));
    }
    if (selectedStation && selectedStation !== '0') {
      filtered = filtered.filter(vente => vente.code_station === selectedStation);
    }
    if (matriculeFilter) {
      filtered = filtered.filter(vente => vente.matricule.includes(matriculeFilter));
    }

    setFilteredVentes(filtered);
    console.log('Applied filters:', { selectedLocalite, selectedPetrolier, selectedStation, matriculeFilter });
  }, [ventes, selectedLocalite, selectedPetrolier, selectedStation, matriculeFilter]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    console.log('Page changed to:', pageNumber);
  };

  const handleDateChange = (setDateFunc) => (e) => {
    setDateFunc(e.target.value);
    fetchVentes();
  };

  const indexOfLastVente = currentPage * ventesPerPage;
  const indexOfFirstVente = indexOfLastVente - ventesPerPage;
  const currentVentes = filteredVentes.slice(indexOfFirstVente, indexOfLastVente);

  const totalVentes = filteredVentes.length;
  const totalQteVendue = filteredVentes.reduce((acc, vente) => acc + vente.qte, 0);

  const totalPages = Math.ceil(filteredVentes.length / ventesPerPage);

  return (
    <>
      <Header user={user} />
      <Container className="mt-4 container-list-ventes">
        <h2>Liste des Ventes</h2>
        <Form className="mb-4">
          <Row>
            <Col>
              <FloatingLabel controlId="dateDebut" label="Date Début">
                <Form.Control
                  type="date"
                  value={dateDebut}
                  onChange={handleDateChange(setDateDebut)}
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="dateFin" label="Date Fin">
                <Form.Control
                  type="date"
                  value={dateFin}
                  onChange={handleDateChange(setDateFin)}
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="localiteSelect" label="Localité">
                <Form.Select
                  aria-label="Localité"
                  value={selectedLocalite}
                  onChange={(e) => setSelectedLocalite(e.target.value)}
                >
                  <option value="0">Toutes les localités</option>
                  {localites.map((localite, index) => (
                    <option key={index} value={localite.id_localite}>
                      {localite.nom_localite}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="petrolierSelect" label="Pétrolier">
                <Form.Select
                  aria-label="Pétrolier"
                  value={selectedPetrolier}
                  onChange={(e) => setSelectedPetrolier(e.target.value)}
                >
                  <option value="0">Tous les pétroliers</option>
                  {petroliers.map((petrolier, index) => (
                    <option key={index} value={petrolier.id_petrolier}>
                      {petrolier.nom_petrolier}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="stationSelect" label="Station">
                <Form.Select
                  aria-label="Station"
                  value={selectedStation}
                  onChange={(e) => setSelectedStation(e.target.value)}
                >
                  <option value="">Toutes les stations</option>
                  {stations.map((station, index) => (
                    <option key={index} value={station.code_station}>
                      {station.nom_station}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="matriculeFilter" label="Matricule">
                <Form.Control
                  type="text"
                  value={matriculeFilter}
                  onChange={(e) => setMatriculeFilter(e.target.value)}
                />
              </FloatingLabel>
            </Col>
          </Row>
        </Form>
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
        <div className="text-center mb-3">
          <strong>Nombre de ventes : {totalVentes}</strong>
          <br />
          <strong>Quantité totale vendue : {totalQteVendue} Tkts soit {totalQteVendue * 10}L</strong>
        </div>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th className="d-none d-md-table-cell">Nom Station</th>
              <th>Quantité</th>
              <th>Matricule</th>
              
              <th className="d-none d-md-table-cell">Localité</th>
              <th className="d-none d-md-table-cell">Pompiste</th>
            </tr>
          </thead>
          <tbody>
            {currentVentes.map((vente) => (
              <tr key={vente.ref_mouv}>
                <td>{new Date(vente.date_mouv).toLocaleDateString()}</td>
                <td className="d-none d-md-table-cell">{vente.nom_station}</td>
                <td>{vente.qte} Tkts soit {vente.qte * 10}L</td>
                <td>{vente.matricule}</td>
               
                <td className="d-none d-md-table-cell">{vente.lieu_op}</td>
                <td className="d-none d-md-table-cell">{vente.saisie_par}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default ListVentes;
