import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import './styles/Download.css';

const Download = () => {

  const images = [
    `${process.env.PUBLIC_URL}/images/bannieres/systeme_android.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/systeme_android1.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/systeme_android2.webp`,
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(true);
      }, 1000);
    }, 6000);

    return () => clearInterval(interval);
  }, [images.length]);

  const backgroundImageStyle = {
    backgroundImage: `url(${images[currentImageIndex]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: '20px',
    transition: 'opacity 1s ease-in-out',
    opacity: fade ? 1 : 0,
  };

  return (
    <Layout>
      <div className="download-background" style={backgroundImageStyle}>
        {/* Bouton flottant centré pour télécharger l'application */}
    
          <div className="download-button">
            <a href={`${process.env.PUBLIC_URL}/download_files/Sycad_P.apk`} download>
              <button className="btn-download">Télécharger APK</button>
            </a>
          </div>
     
        {/* Texte descriptif en bas de page */}
        <div className="download-description">
          <p>
            Cette application est destinée aux gestionnaires de carburant sous douane pour faciliter la gestion et le suivi des transactions.
            Pour installer l'application mobile Sycad, cliquez sur le bouton ci-dessus.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Download;
