import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';

const PopupDemande = ({ show, onHide, demande, refreshDemandes, etats, handleSave, user }) => {
  const [refDoc, setRefDoc] = useState('');
  const [nextStepDisabled, setNextStepDisabled] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [apiResult, setApiResult] = useState('');

  useEffect(() => {
    if (demande && (demande.id_etat === 2 || demande.id_etat === 3 || demande.id_etat === 4 || demande.id_etat === 5)) {
      setNextStepDisabled(false);
    } else if (demande && demande.id_etat === 5) {
      setNextStepDisabled(true);
    } else {
      setNextStepDisabled(false);
    }
    console.log('Demande:', demande);
  }, [demande]);

  const calculateTickets = (qteCmde) => {
    return Math.ceil(qteCmde / 10);
  };

  const getTafLabel = (id_etat) => {
    if (!etats || etats.length === 0) return "Étape suivante";
    const etat = etats.find(etat => etat.id_etat === id_etat);
    return etat ? `A faire : ${etat.taf}` : "Étape suivante";
  };

  const getNextEtat = (code_groupe) => {
    switch (code_groupe) {
      case 'PECHE':
        return 2;
      case 'COMMISSION':
          return 3;
      case 'SADMIN':
          return 4;
      case 'DOUANE':
        if (user.id_groupe === 3 || user.id_groupe === 9) { 
          if (demande.id_etat === -1) {
            return 5;
          }
          else{
            return 6;
          }       
        }
        else {
              return 5;       
        }
      default:
        return demande.id_etat; // Reste inchangé si le groupe n'est pas reconnu
    }
  };
  
  const handleDebloquer = async (   ) => {
    if (!demande || !user) return;
    if (user.mdp_changer === false) {
      setApiResult('Veuillez changer votre mot de passe avant de pouvoir exécuter cette action');
      return;
    }
    if (demande.id_etat === 6) {
      setApiResult('Aucune étape à faire');
      return;
    }
    if  (user.code_groupe !== 'DOUANE') {
      setApiResult('Aucune autorisation pour faire cette action');
      return;
    }
    
    const newIdEtat = 0;
    const query = `
      SELECT * FROM maj_etat_demande(
        ${demande.id_demande},
        ${newIdEtat},
        '${refDoc}',
        ${user.id_agent}
      )
    `;
    console.log('Requete deblocage demande :', query);
    try {
      const result = await envoyerRequeteApi(query, '');
      console.log('Next step result:', result);

      const statut = result.datas[0]?.maj_etat_demande?.statut;
      setApiResult(statut);
      refreshDemandes(); // Refresh the list of demandes
      onHide(); // Close the modal
     
    } catch (error) {
      console.error('Error in handleNextStep:', error);
      setApiResult(`Erreur: ${error.message}`);
    }
  };

  
  const handleRejet = async (   ) => {
    if (!demande || !user) return;
    if (user.mdp_changer === false) {
      setApiResult('Veuillez changer votre mot de passe avant de pouvoir exécuter cette action');
      return;
    }
    if (demande.id_etat === 6) {
      setApiResult('Aucune étape à faire');
      return;
    }
    if  (user.code_groupe !== 'DOUANE') {
      setApiResult('Aucune autorisation pour faire cette action');
      return;
    }
    if  (demande.id_etat === -1) {
      setApiResult('La demane est deja rejetée');
      return;
    }
    const newIdEtat = -1;
    const query = `
      SELECT * FROM maj_etat_demande(
        ${demande.id_demande},
        ${newIdEtat},
        '${refDoc}',
        ${user.id_agent}
      )
    `;
    console.log('Requete rejet demande :', query);
    try {
      const result = await envoyerRequeteApi(query, '');
      console.log('Next step result:', result);

      const statut = result.datas[0]?.maj_etat_demande?.statut;
      setApiResult(statut);
      refreshDemandes(); // Refresh the list of demandes
      onHide(); // Close the modal
     
    } catch (error) {
      console.error('Error in handleNextStep:', error);
      setApiResult(`Erreur: ${error.message}`);
    }
  };

  const handleNextStep = async (   ) => {
    if (!demande || !user) return;
    if (user.mdp_changer === false) {
      setApiResult('Veuillez changer votre mot de passe avant de pouvoir exécuter cette action');
      return;
    }
    if (demande.id_etat >=2 && user.code_groupe === 'PECHE') {
      alert('Aucune étape à faire');
      return;
    }
    if (demande.id_etat >=5 && user.code_groupe === 'COMMISSION') {
      alert('Aucune étape à faire');
      return;
    }
    
    if (demande.id_etat > 3 && user.code_groupe === 'SADMIN') {
      alert('Aucune étape à faire');
      return;
    }
    if ( user.code_groupe === 'DOUANE') {
         if (  (demande.id_etat >= 1 && demande.id_etat <= 3) || demande.id_etat === 6) {
            alert('Aucune étape à faire');
            return;
      }
    // testons si la demande a franchi l'etat Chef de brigade
    if ((demande.id_etat === 5 || demande.id_etat === -1 )  && user.id_groupe === 16) {
      alert('Aucune étape à faire');
      return;
    }
    }

  
    const newIdEtat = getNextEtat(user.code_groupe);

    if ((newIdEtat === 1 || newIdEtat === 3 || newIdEtat === 4 || newIdEtat === 5) && !refDoc) {
      setAlertVisible(true);
      return;
    }

    const query = `
      SELECT * FROM maj_etat_demande(
        ${demande.id_demande},
        ${newIdEtat},
        '${refDoc}',
        ${user.id_agent}
      )
    `;
    console.log('Requete traitement demande :', query);
    try {
      const result = await envoyerRequeteApi(query, '');
      console.log('Next step result:', result);

      const statut = result.datas[0]?.maj_etat_demande?.statut;
      setApiResult(statut);
      refreshDemandes(); // Refresh the list of demandes
      onHide(); // Close the modal
      // Handle the result if needed, e.g., show a message to the user
    } catch (error) {
      console.error('Error in handleNextStep:', error);
      setApiResult(`Erreur: ${error.message}`);
    }
  };
  const formatDate = (dateString) => {
    if (!dateString) return "Non disponible";
    
    const date = new Date(dateString);
    return date.toLocaleString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  
  if (!demande) return null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Détails de la demande: {demande.ref_demande}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alertVisible && (
          <Alert variant="danger" onClose={() => setAlertVisible(false)} dismissible>
            Le champ "Référence Document" est obligatoire pour justifier la validation".
          </Alert>
        )}
        {apiResult && (
          <Alert variant="info" onClose={() => setApiResult('')} dismissible>
            {apiResult}
          </Alert>
        )}
        <Form>
          <Form.Group>
            <Form.Label>État</Form.Label>
            <Form.Control type="text" readOnly defaultValue={demande.libelle} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Groupe Suivi</Form.Label>
            <div className="groupe-suivi">
              <p>Crée le: {demande.date_demande} *** Par: {demande.nom_peche || "Non disponible"}</p>
              <p>Payé le: {formatDate(demande.date_paiement)} *** Par: {demande.nom_petrolier || "Non disponible"}</p>
              <p>Livré le: {formatDate(demande.date_livraison)} *** Par: {demande.nom_agent || "Non disponible"}</p>
              <p>Pré-validé le: {formatDate(demande.date_validation)} *** Par: {demande.nom_douanebg || "Non disponible"}</p>
              <p>Validé le: {demande.id_etat === -1 ? "DEMANDE REJETEE" : formatDate(demande.date_autorisation)} *** Par: {demande.nom_douane || "Non disponible"}</p>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Quantité commandée</Form.Label>
            <Form.Control type="text" readOnly defaultValue={`${demande.qte_cmde} litres soit ${calculateTickets(demande.qte_cmde)} Tickets`} />
          </Form.Group>
          {(demande.id_etat === 1 || demande.id_etat === -1 ||  demande.id_etat === 2 || demande.id_etat === 3 || demande.id_etat === 4) && (
            <Form.Group>
              <Form.Label>Référence Document </Form.Label>
              <Form.Control
                type="text"
                placeholder="N° du document administratif ou justificatif"
                value={refDoc}
                onChange={(e) => setRefDoc(e.target.value)}
                required
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={demande.id_etat === -1 ? handleDebloquer : handleNextStep}
          className="rounded-button btn-block"
          disabled={nextStepDisabled}
          hidden={(demande.id_etat === -1 && user.id_groupe === 16) || (demande.id_etat === -1 && user.code_groupe !== 'DOUANE')}
          //hidden={demande.id_etat === -1 && user.code_groupe !== 'DOUANE'}
        >
          {getTafLabel(demande.id_etat)}
        </Button>
        <Button
          variant="success"
          onClick={handleRejet}
          className="rounded-button btn-danger"
          hidden={(user.id_groupe === 3 || user.id_groupe === 9) || !(demande.id_etat === -1 || demande.id_etat === 4 || demande.id_etat === 5)}
        >
         Rejeter la demande
        </Button>
        <Button variant="secondary" onClick={onHide} className="rounded-button">
          Annuler
        </Button>
        <Button 
            variant="primary" 
            onClick={handleSave} 
            hidden={user.code_groupe === 'DOUANE' || user.code_groupe ===  'COMMISSION' || user.code_groupe === 'SADMIN'} 
            className="rounded-button">
          Sauver
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupDemande;
