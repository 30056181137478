import React, { useState, useEffect, useContext, useCallback } from 'react';
import { getReponseApi } from '../apis/api_backend';
import { Alert } from 'react-bootstrap';
import '../styles/ListDemande.css';
import { SycadContext } from '../../contexts/SycadContext';
import AddCommande from './AddCommande';
import PopupDemande from './PopupDemande';
import Header from '../Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClipboardList, faQrcode } from '@fortawesome/free-solid-svg-icons';

const ListDemande = () => {
  const [showAlert, setShowAlert] = useState(false);
  const { user } = useContext(SycadContext);
  const [demandes, setDemandes] = useState([]);
  const [dateDebut, setDateDebut] = useState('');
  const [dateFin, setDateFin] = useState('');
  const [etatDemande, setEtatDemande] = useState(''); // état sélectionné
  const [ticketsDispo, setTicketsDispo] = useState(0);
  const [etats, setEtats] = useState([]);
  const [selectedDemande, setSelectedDemande] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showAddCommande, setShowAddCommande] = useState(false);
  const [filteredDemandesCount, setFilteredDemandesCount] = useState(0);

  const isAdminOrPeche = user && (user.code_groupe === "ADMIN" || user.code_groupe === "PECHE");
  const isUserAuthorized = user && user.code_groupe === "PECHE";

  const formatDate = useCallback((date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }, []);

  // Initialiser les dates au début de l'année en cours et à aujourd'hui
  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startDate = new Date(currentYear, 0, 1);
    setDateDebut(formatDate(startDate));
    setDateFin(formatDate(currentDate));
  }, [formatDate]);

  // Fetch des demandes en utilisant l'API '/demandes'
  const fetchDemandes = useCallback(async () => {
    try {
      const response = await getReponseApi('/api/tickets/list', 'GET');
      if (response && Array.isArray(response)) {
        setDemandes(response);
      } else {
        console.error('La réponse n\'est pas un tableau:', response);
        setDemandes([]);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des demandes', error);
      setDemandes([]);
    }
  }, []);

  useEffect(() => {
    setFilteredDemandesCount(demandes.length);
  }, [demandes]);

  // Charger les demandes lors de l'initialisation du composant
  useEffect(() => {
    fetchDemandes();
  }, [fetchDemandes]);

  // Fetch des états des demandes
  const fetchEtats = useCallback(async () => {
    try {
      const response = await getReponseApi('/api/tickets/etats_demande', 'GET');
      setEtats(response || []);  // Assumez que le backend renvoie les états
    } catch (error) {
      console.error('Erreur lors de la récupération des états', error);
    }
  }, []);

  // Charger les états lors de l'initialisation du composant
  useEffect(() => {
    fetchEtats();
  }, [fetchEtats]);

  // Fetch des tickets disponibles pour un poste (en utilisant l'API définie)
  const fetchTicketsDispo = useCallback(async () => {
    try {
      const response = await getReponseApi('/api/tickets/tickets_global', 'GET'); // Vous pouvez ajuster le pid_poste selon les besoins
      setTicketsDispo(response.tickets_dispo || 0);
    } catch (error) {
      console.error('Erreur lors de la récupération des tickets disponibles', error);
    }
  }, []);

  useEffect(() => {
    fetchTicketsDispo();
    const interval = setInterval(fetchTicketsDispo, 30000); // Actualiser les tickets toutes les 30 secondes
    return () => clearInterval(interval); // Nettoyer l'interval lors du démontage du composant
  }, [fetchTicketsDispo]);

  // Filtrer les demandes selon la date et l'état
  const handleFilter = useCallback(async () => {
    const filterParams = {
      dateDebut,
      dateFin,
      etatDemande: etatDemande || "" // Assurez-vous de bien envoyer un état valide ou une chaîne vide
    };

    try {
      const response = await getReponseApi('/api/tickets/filter', 'POST', filterParams);
      if (response && Array.isArray(response)) {
        setDemandes(response);
        setFilteredDemandesCount(response.length);
      } else {
        console.error('La réponse du filtrage n\'est pas un tableau:', response);
        setDemandes([]);
        setFilteredDemandesCount(0);
      }
    } catch (error) {
      console.error('Erreur lors du filtrage des demandes', error);
      setDemandes([]);
      setFilteredDemandesCount(0);
    }
  }, [dateDebut, dateFin, etatDemande]);

  // Exécuter le filtrage chaque fois que l'utilisateur change l'état dans le select
  useEffect(() => {
    handleFilter();
  }, [etatDemande, handleFilter]); // Appeler handleFilter quand etatDemande change

  // Gestion de la création d'une nouvelle demande
  const handleNewDemande = () => {
    if (user.mdp_changer === false) {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 5000); // L'alerte disparaît après 5 secondes
    } else {
      setShowAddCommande(true);
    }
  };

  // Mise à jour de l'état sélectionné et filtrer automatiquement
  const handleEtatChange = useCallback((e) => {
    setEtatDemande(e.target.value);
  }, []);

  // Supprimer une demande en utilisant l'API DELETE
  const handleDelete = useCallback(async (demande) => {
    if (demande.id_etat >= 3) {
      alert('Cette demande est hors portée, vous ne pouvez pas la supprimer.');
      return;
    }
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette demande ? Cette action est irréversible.')) {
      try {
        const dataToSend = {
          id: demande.id,
          userId: user.id_agent,
        };
  
        // Appel à l'API pour supprimer la demande
        const response = await getReponseApi(`/api/tickets/delete`, 'POST', dataToSend);
  
        if (response.message === 'Demande supprimée avec succès.') {
          alert('Suppression réussie.');
          fetchDemandes(); // Rafraîchir la liste des demandes après la suppression réussie
        } else {
          alert(`Erreur lors de la suppression de la demande : ${response.message}`);
        }
      } catch (error) {
        console.error('Erreur lors de la suppression de la demande', error);
        alert('Une erreur s\'est produite lors de la tentative de suppression.');
      }
    }
  }, [fetchDemandes, user]);

  // Afficher une demande dans le popup
  const handleView = useCallback((demande) => {
    setSelectedDemande(demande);
    setShowPopup(true);
  }, []);

  // Calculer le nombre de tickets à partir de la quantité commandée
  const calculateTickets = useCallback((qteCmde) => {
    return Math.ceil(qteCmde / 10);
  }, []);

  const getIndicativeText = useCallback(() => {
    if (isAdminOrPeche) {
      return "Vous pouvez voir et filtrer toutes les demandes.";
    }
    switch (user.code_groupe) {
      case 'PETROLIER':
        return "Vous ne pouvez agir que sur les demandes ayant le statut PAIEMENT ATTENDU";
      case 'SADMIN':
        return "Vous ne pouvez agir que sur les demandes ayant le statut LIVRAISON ATTENDUE";
      case 'DOUANE':
        if (user.id_groupe === 3 || user.id_groupe === 9) {
          return "Vous ne pouvez agir que sur les demandes ayant le statut ACCORD CHEF DE BRIGADE/REJETEE";
        } else {
          return "Vous ne pouvez agir que sur les demandes ayant le statut TICKETS GENERES";
        }
      default:
        return "";
    }
  }, [isAdminOrPeche, user]);

  return (
    <>
      <Header user={user} />
      <div className="container-list-demandes">
        <h2>Liste des demandes</h2>
        <div className="tickets-dispo-circle">
          <span className="tickets-dispo-number">{ticketsDispo/10}</span>
          <span className="tickets-dispo-label">Tickets disponibles</span>
        </div>
        <div className="alert-indicative">{getIndicativeText()}</div>
        <div className="form-container">
          <div className="form-group">
            <label htmlFor="dateDebut">Date de début</label>
            <input
              type="date"
              id="dateDebut"
              value={dateDebut}
              onChange={(e) => setDateDebut(e.target.value)}
            />
            <label htmlFor="dateFin">Date de fin</label>
            <input
              type="date"
              id="dateFin"
              value={dateFin}
              onChange={(e) => setDateFin(e.target.value)}
            />
            <div className="form-group">
              <label htmlFor="etatDemande">État de la demande</label>
              <select
                id="etatDemande"
                value={etatDemande}
                onChange={handleEtatChange} // Mise à jour automatique de l'état et exécution du filtrage
              >
                <option value="">Tous les états</option>
                {etats.map((etat) => (
                  <option key={etat.id_etat} value={etat.id_etat}>
                    {etat.libelle_etat}
                  </option>
                ))}
              </select>
            </div>
            <div className="filtered-count-label">
              Nombre de demandes affichées : {filteredDemandesCount}
            </div>
            <div className="button-group">
              <button className="rounded-button btn-primary" onClick={handleFilter}>
                Filtrer
              </button>
            </div>
            <button
              className="bouton-nouveau"
              onClick={handleNewDemande}
              disabled={!isUserAuthorized}
              hidden={!isUserAuthorized}
            >
              Nouvelle demande
            </button>
            {showAlert && (
              <Alert variant="warning" className="mt-2">
                Veuillez changer votre mot de passe avant de faire une nouvelle demande.
              </Alert>
            )}
          </div>
        </div>
        <div className="demandes-container">
          {demandes.map((demande) => (
            <div key={demande.id_demande} className="card-demande">
              <img className="ticket-img" src={`${process.env.PUBLIC_URL}/images/ticket-img.png`} alt="Ticketpetrol" />
              <h3>Réf: {demande.ref_demande}</h3>
              <p className="text-muted">
                <FontAwesomeIcon icon={faCheckCircle} /> {demande.date_demande}
              </p>
              <p>
                <FontAwesomeIcon icon={faClipboardList} /> <strong>État:</strong> {demande.libelle}
              </p>
              <p>
                <FontAwesomeIcon icon={faQrcode} /> <strong>Quantité:</strong> {demande.qte_cmde} litres soit{' '}
                {calculateTickets(demande.qte_cmde)} Tickets
              </p>
              <div className="button-group">
                <button className="rounded-button btn-info" onClick={() => handleView(demande)}>
                  Afficher
                </button>
                <button
                  className="rounded-button btn-danger"
                  hidden={user.code_groupe === 'DOUANE' || user.code_groupe !== 'ADMIN'}
                  onClick={() => handleDelete(demande)}
                >
                  Supprimer
                </button>
              </div>
            </div>
          ))}
        </div>
        <PopupDemande
          demande={selectedDemande}
          show={showPopup}
          onHide={() => setShowPopup(false)}
          refreshDemandes={fetchDemandes}
          etats={etats}
          user={user}
        />
        <AddCommande
          show={showAddCommande}
          onHide={() => setShowAddCommande(false)}
          refreshDemandes={fetchDemandes}
          user={user}
        />
      </div>
    </>
  );
};

export default ListDemande;
