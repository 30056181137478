import React, { useState, useEffect } from 'react';
import api from '../api'; //chemin du backend
import { Container, Button } from 'react-bootstrap';
import './styles/Home.css';
import Footer from './Footer';

function Calculer_DeadLine() {
  const dateLimite = new Date('2024-10-24'); // Date limite fixée au 24 octobre 2024
  const aujourdHui = new Date(); // Date actuelle

  // Calculer la différence en millisecondes
  const difference = dateLimite.getTime() - aujourdHui.getTime();

  // Convertir la différence en jours
  const joursRestants = Math.ceil(difference / (1000 * 60 * 60 * 24)); // 1000ms * 60s * 60m * 24h

  // Si la différence est positive, la date limite n'est pas encore atteinte
  if (joursRestants > 0) {
    return {
      isActif: true,
      sLibelle: (
        <>
          Activation (J-{joursRestants}jrs) <br /> Limite : {dateLimite.toLocaleDateString()}
        </>
      ), // Utiliser JSX pour créer un retour à la ligne
    };
  } else {
    return {
      isActif: false,
      sLibelle: 'Période Dépassée', // Libellé lorsque la période est dépassée
    };
  }
}


function Home() {
  const images = [
    `${process.env.PUBLIC_URL}/images/bannieres/image_fond1.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/image_fond2.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/image_fond3.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/image_fond.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/image_fond5.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/image_fond4.webp`,
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(true); // Contrôle de l'animation de fondu
  const [message, setMessage] = useState('');
  const [activationData, setActivationData] = useState({ isActif: true, sLibelle: '' });
  // Appel à la fonction Calculer_DeadLine
  useEffect(() => {
    const data = Calculer_DeadLine(); // Appel à la fonction pour obtenir l'état du bouton
    setActivationData(data); // Mettre à jour l'état avec les résultats de la fonction
  }, []);

  useEffect(() => {
    // Appel à l'API backend
    api.get('/api')
      .then((response) => {
        setMessage(response.data);
      })
      .catch((error) => {
        console.error('Erreur lors de appel à API :', error);
      });
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Lancer la sortie en fondu

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length); // Changer d'image après l'animation de disparition
        setFade(true); // Relancer l'entrée en fondu
      }, 1000); // Durée de l'effet de fondu (1 seconde)

    }, 6000); // Changer d'image toutes les 6 secondes (incluant 1 seconde pour la transition)

    return () => clearInterval(interval); // Nettoyer l'intervalle à la fin du composant
  }, [images.length]);

  return (
    <div className={`home-background ${fade ? 'fade-in' : 'fade-out'}`} style={{ backgroundImage: `url(${images[currentImageIndex]})` }}>
      <header className="home-header">
        <h1>Bienvenue sur SYCAD</h1>
        <br></br>
        <p>Système de gestion du carburant sous douane</p>
      </header>
     
    {/* Bouton pour ouvrir la page Code en haut à droite */}
    {activationData.isActif && (
        <div className="btnCode">
          <Button href="/code" variant="secondary">
            {activationData.sLibelle} {/* Afficher le libellé en fonction de l'état */}
          </Button>
        </div>
      )}

      {!activationData.isActif && (
        <div className="btnCode">
          <Button variant="secondary" disabled>
            {activationData.sLibelle} {/* Période Dépassée */}
          </Button>
        </div>
      )}
    {/* Ajout du bouton flottant image pour la page de téléchargement */}
      <div className="btnDownload">
        <a href="/download" target="_blank" rel="noopener noreferrer">
          <img src={`${process.env.PUBLIC_URL}/images/download-icon.png`} alt="Download" />
         

        </a>
      </div>
      <Container className="home-content text-center">
        <div className="btnConnexion">
          <Button href="/login">Entrer</Button>
        </div>
      </Container>
    <p style={{ color: 'transparent' }}>{message}</p>
      <Footer />

    </div>
  );
};

export default Home;
