// frontend/src/components/apis/api_backend.js

// Fonction pour récupérer le token JWT (à adapter selon la manière dont vous stockez le token, ex: localStorage)
function getToken() {
    return localStorage.getItem('token'); // Suppose que le token est stocké dans localStorage
}

export async function getReponseApi(endpoint, method = 'GET', bodyParams = null, additionalHeaders = {}) {
    const baseUrl = process.env.REACT_APP_API_URL; // URL de votre backend, à adapter selon votre environnement

    const url = `${baseUrl}${endpoint}`; // Construire l'URL complète avec l'endpoint
    
    // Récupérer le token JWT
    const token = getToken();

    // Préparation des headers avec le token JWT
    const headers = {
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` }), // Ajoute le token seulement s'il existe
        ...additionalHeaders // Ajoute des headers supplémentaires si nécessaires
    };

    // Options pour la requête fetch
    const options = {
        method,
        headers,
    };

    // Si le bodyParams est fourni (cas des requêtes POST, PUT, etc.), on l'ajoute
    if (bodyParams) {
        options.body = JSON.stringify(bodyParams);
    }

    try {
        const response = await fetch(url, options);

        // Vérification de la réponse
        if (!response.ok) {
            // Gestion améliorée des erreurs - Retournez des informations supplémentaires sur l'erreur
            console.error(`Network response was not ok, status: ${response.status}, URL: ${url}`);
            return { error: true, status: response.status, message: `Error: ${response.statusText}` };
        }

        const data = await response.json(); // Conversion en objet JSON

        return data;
    } catch (error) {
        console.error('Erreur lors de la requête API:', error);
        return { error: true, message: error.message }; // Retourne un objet avec une clé `error` en cas d'erreur
    }
}
