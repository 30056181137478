import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import GetPirogue from '../Admin/GetPirogue';
import '../styles/AddSubvention.css';
import { SycadContext } from '../../contexts/SycadContext';
import envoyerSMS from '../apis/send_message';

const AddSubvention = ({ show, onHide, refreshSubventions, fetchTicketsDispo, ticketsDispo }) => {
  const { user, connectedPoste } = useContext(SycadContext);  // Récupérer l'utilisateur connecté
  const [matricule, setMatricule] = useState('');
  const [qte, setQte] = useState('');
  const [pirogueInfo, setPirogueInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showScanner, setShowScanner] = useState(false);

  // Réinitialiser les champs lorsque le modal s'ouvre
  useEffect(() => {
    if (show) {
      setMatricule('');
      setQte('');
      setPirogueInfo(null);
      setError('');
    }
  }, [show]);

  // Fonction pour gérer le changement de matricule
  const handleMatriculeChange = (e) => {
    const value = e.target.value;
    setMatricule(value);

    if (value.length < 10) {
      // Réinitialiser les états si la longueur est inférieure à 10
      setPirogueInfo(null);
      setError('');
    } else if (value.length === 10) {
      // Lancer la recherche de la pirogue si la longueur atteint 10
      handleSearchPirogue(value);
    }
  };

  // Fonction pour rechercher la pirogue
  const handleSearchPirogue = async (matricule) => {
    setLoading(true);
    setError('');
    try {
      const query = `SELECT a_qui_subventionne(${connectedPoste.id_poste}, '${matricule}')`;
      console.log(query);
      const response = await envoyerRequeteApi(query, '');
      console.log(response);

      if (response.datas.length === 0 || response.datas[0].a_qui_subventionne.code === 404) {
        setError(response.datas[0]?.a_qui_subventionne?.message || 'Pirogue non trouvée.');
        setPirogueInfo(null);
        return;
      } else {
        const pirogueData = response.datas[0].a_qui_subventionne.donnee;
        setPirogueInfo(pirogueData);
      }

    } catch (error) {
      console.error('Error fetching pirogue info', error);
      setError('Erreur lors de la recherche de la pirogue.');
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour gérer le résultat du scan
  const handleScanResult = (pirogue) => {
    setMatricule(pirogue.matricule);
    if (pirogue) {
      setPirogueInfo(pirogue);
      setShowScanner(false);
    } else {
      setPirogueInfo(null);
    }
  };

  // Fonction pour soumettre la subvention
  const handleSubmit = async () => {
    if (qte > ticketsDispo) {
      setError('Le nombre de tickets ne doit pas dépasser le nombre de tickets disponibles.');
      setQte('');
      return;
    }

    try {
      // Ajouter la nouvelle subvention  pour un matricule
      const query = `INSERT INTO ticket_remis (matricule, qte, id_agent) VALUES ('${matricule}', ${qte}, '${user.id_agent}')`;
      await envoyerRequeteApi(query, '');

      // Envoyer un SMS de notification au propriétaire de la pirogue
      const message = `Salam, Vous venez de recevoir ${qte} tickets de carburant, soit ${qte * 10} litres. Maanam,Yaa ngi jot ${qte} tiket yu gasoil, muy ${qte * 10} liitar.`;
      await envoyerSMS(pirogueInfo.telephone, message);

      refreshSubventions();
      fetchTicketsDispo(); // Mettre à jour les tickets disponibles après l'ajout
      onHide();
      // Réinitialiser les champs
      setMatricule('');
      setQte('');
      setPirogueInfo(null);
    } catch (error) {
      console.error('Error adding subvention', error);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Saisie d'une nouvelle subvention</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Tickets Disponibles</Form.Label>
              <Form.Control type="text" readOnly value={ticketsDispo} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Entrer le matricule de la pirogue</Form.Label>
              <div className="d-flex">
                <Form.Control
                  type="text"
                  value={matricule}
                  onChange={handleMatriculeChange}
                />
              </div>
            </Form.Group>
            {loading ? (
              <Spinner animation="border" />
            ) : (
              pirogueInfo && (
                <div className="pirogue-info">
                  <strong>Info de la pirogue</strong>
                  <p>Nom: {pirogueInfo.nom_pirogue}</p>
                  <p>Quota: {pirogueInfo.quota}</p>
                  <p>Téléphone: {pirogueInfo.telephone}</p>
                  <p>Poste: {pirogueInfo.nom_poste}</p>
                </div>
              )
            )}
            <Form.Group className="mb-3">
              <Form.Label>Entrer le nombre de tickets</Form.Label>
              <Form.Control
                type="number"
                value={qte}
                onChange={(e) => setQte(e.target.value)}
              />
              <Form.Text className="text-muted">1 Ticket = 10 Litres de carburant</Form.Text>
            </Form.Group>
            {error && <Alert variant="danger">{error}</Alert>}
            <div className="d-flex justify-content-around">
              <Button variant="primary" onClick={handleSubmit}>Enregistrer</Button>
              <Button variant="secondary" onClick={onHide}>Annuler</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <GetPirogue show={showScanner} onHide={() => setShowScanner(false)} onScan={handleScanResult} />
    </>
  );
};

export default AddSubvention;
