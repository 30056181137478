import React, { useState, useEffect, useContext } from 'react';
import { Container, Form, Button, Row, Col, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';
import { QrReader } from 'react-qr-reader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGasPump, faPhone, faShip, faBan, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import '../styles/ScanPay.css';
import envoyerSMS from '../apis/send_message';

const ScanPay = ({ show }) => {
  const { user, connectedStation } = useContext(SycadContext);
  const [matricule, setMatricule] = useState('');
  const [quantite, setQuantite] = useState('');
  const [error, setError] = useState('');
  const [pirogue, setPirogue] = useState(null);
  const [pirogues, setPirogues] = useState([]);
  const [showScanner, setShowScanner] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [pin, setPin] = useState('');
  const [shouldStopScanner, setShouldStopScanner] = useState(false);
  const [decodeErrorCount, setDecodeErrorCount] = useState(0);

  const navigate = useNavigate();

  // Réinitialiser les champs lorsque le modal s'ouvre
  useEffect(() => {
    if (show) {
      setMatricule('');
      setQuantite('');
      setPirogue(null);
      setError('');
    }
  }, [show]);

  useEffect(() => {
    const fetchPirogues = async () => {
      if (!connectedStation) {
        setError('Station non connectée.');
        return;
      }

      const query = `SELECT * FROM a_qui_vendre(${connectedStation.id_localite})`;
      try {
        const response = await envoyerRequeteApi(query, '');
        setPirogues(response.datas);
      } catch (err) {
        setError('Erreur lors de la récupération des données.');
      }
    };

    fetchPirogues();
  }, [connectedStation]);

  const handleResult = (result, error) => {
    if (result) {
      const matriculeLu = result.text;
      setMatricule(matriculeLu);
      searchPirogue(matriculeLu);
      setShouldStopScanner(true);
      setShowScanner(false);
    }

    if (error) {
      console.error('Erreur de décodage du QR code', error);
      setDecodeErrorCount((prev) => prev + 1);

      if (decodeErrorCount > 5) {
        handleClose();
        setError("Trop d'erreurs lors du décodage du QR code. Veuillez réessayer.");
        setShouldStopScanner(true);
        setShowScanner(false);
      }
    }
  };

  const handleMatriculeChange = (e) => {
    const value = e.target.value;
    setMatricule(value);

    if (value.length < 10) {
      // Réinitialiser les états si la longueur est inférieure à 10
      setPirogue(null);
      setError('');
    } else if (value.length === 10) {
      // Lancer la recherche de la pirogue si la longueur atteint 10
      searchPirogue(value);
    }
  };

  const searchPirogue = (matricule) => {
    const pirogueTrouvee = pirogues.find((p) => p.matricule === matricule);
    if (pirogueTrouvee) {
      setPirogue(pirogueTrouvee);
      setError('');
    } else {
      setPirogue(null);
      setMatricule('');
      setError('Aucune pirogue trouvée avec ce matricule.');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchPirogue(matricule);
    }
  };

  const permettreAchat = async (quantite, matricule) => {
    const gsDateactuelle = new Date().toISOString();
    const d1 = gsDateactuelle.split('T')[0];
    const cons = parseInt(quantite, 10);
    let query = '';
    const mode = pirogue.jour_o_mois ? '1' : '0';
    query = `SELECT * FROM quel_volume_conso('${matricule}', '${d1}', '${mode}')`;

    const sResultat = await envoyerRequeteApi(query, '');
    if (!sResultat || !sResultat.datas) {
      return 'SQL Query Execute Error';
    }

    const { volume_max: nVolume, nbre_rotation: nNbre } = sResultat.datas[0];
    if (nNbre === 0 && nVolume === 0) {
      return 'OK';
    }

    if (pirogue.volume < nVolume + cons) {
      return pirogue.jour_o_mois
        ? "Cette carte a atteint le volume limite d'achat journalier."
        : "Cette carte a atteint le volume limite d'achat mensuel.";
    }

    if (pirogue.nRotation < nNbre + 1) {
      return pirogue.jour_o_mois
        ? "Cette carte a atteint la limite d'utilisation journalière."
        : "Cette carte a atteint la limite d'utilisation mensuelle.";
    }

    return 'OK';
  };

  const handlePay = async () => {
    if (pirogue && parseInt(quantite, 10) > pirogue.quota) {
      setError('La quantité saisie dépasse le quota de la pirogue.');
      return;
    }

    const msg = await permettreAchat(quantite, matricule);
    if (msg !== 'OK') {
      setError(msg);
      return;
    }
    setPin('');
    setShowPinModal(true);
  };

  const saveVente = async (matricule, quantite, pin) => {
    let query = `SELECT btrim(pin) as pin FROM pin_vente WHERE matricule = '${matricule}'`;
    const sResultatpin = await envoyerRequeteApi(query, '');
    const pinResult = sResultatpin && sResultatpin.datas && sResultatpin.datas[0];
    if (!pinResult || pinResult.pin !== pin) {
      setError('Le code PIN saisi est incorrect.');
      return false;
    }

    query = `SELECT * FROM sauver_ventestation('${matricule}', ${quantite}, ${user.id_sgroupe}, '${pin}')`;
    const sResultat = await envoyerRequeteApi(query, '');
    if (!sResultat || !sResultat.datas || sResultat.datas[0].sauver_ventestation.ref_mouv === 'echec') {
      setError('Échec de la transaction. Veuillez réessayer.');
      return false;
    }
    return sResultat.datas[0].sauver_ventestation.ref_mouv;
  };

  const handleConfirmPay = async () => {
    const refMouv = await saveVente(matricule, quantite, pin);
    if (refMouv) {
      const nouveauSolde = pirogue.quota - parseInt(quantite, 10);
      const message = `Votre nouvelle transaction a réussi. Nouveau solde de ticket: ${nouveauSolde} litres.`;
      const numeroTelephone = pirogue.telephone;

      await envoyerSMS(numeroTelephone, message);

      alert(`BRAVO. La transaction est effectuée avec succès avec comme référence : ${refMouv}`);
      setMatricule('');
      setQuantite('');
      setPirogue(null);
      setPin('');
      setError('');
    } else {
      alert('ECHEC TRANSACTION. Vente impossible, vérifier le code pin.');
    }
    setShowPinModal(false);
  };

  const handleClose = () => {
    navigate('/station');
  };

  return (
    <div className="scanpay-background">
      <Container className="scanpay-container">
        <img src={`${process.env.PUBLIC_URL}/images/scanpay.png`} alt="Logo" className="scanpay-logo" />
        <h2>ScanPay</h2>
        <Form>
          <Form.Group as={Row} className="mb-3 align-items-center">
            <Form.Label column sm={2}>Matricule</Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                value={matricule}
                disabled={connectedStation.bloquer}
                onChange={handleMatriculeChange}
                onKeyPress={handleKeyPress}
                placeholder="Entrez le matricule"
                className="rounded-input"
              />
            </Col>
            <Col sm={2}>
              <Button variant="primary" disabled={connectedStation.bloquer} onClick={() => setShowScanner(true)} className="rounded-button">
                <img src={`${process.env.PUBLIC_URL}/images/scanicone.png`} alt="Scan" className="scan-icon" /> Scanner
              </Button>
            </Col>
          </Form.Group>
          {pirogue && (
            <div className="pirogue-details">
              <p><FontAwesomeIcon icon={faGasPump} /> Quota: {pirogue.quota}</p>
              <p><FontAwesomeIcon icon={faPhone} /> Téléphone: {pirogue.telephone}</p>
              <p><FontAwesomeIcon icon={faShip} /> Nom Pirogue: {pirogue.nom_pirogue}</p>
              <p>
                <FontAwesomeIcon icon={pirogue.est_change ? faBan : faCheckCircle} color={pirogue.est_change ? 'green' : 'red'} /> 
                <strong> Code secret changé :</strong> {pirogue.est_change ? 'Oui' : 'Non'}
              </p>
              <p>
                <FontAwesomeIcon icon={pirogue.bloquer ? faBan : faCheckCircle} color={pirogue.bloquer ? 'red' : 'green'} /> 
                <strong> Bloqué:</strong> {pirogue.bloquer ? 'Oui' : 'Non'}
              </p>
            </div>
          )}
          <Form.Group className="mb-3">
            {pirogue && pirogue.bloquer && (
              <p className="alert alert-danger">Cette pirogue est bloquée.</p>
            )}
            <Form.Label>{connectedStation.bloquer ? 'Station est bloquée par la douane' : 'Quantité'}</Form.Label>
            
            <Form.Control
              type="number"
              value={quantite}
              onChange={(e) => setQuantite(e.target.value)}
              placeholder={
                connectedStation.bloquer
                  ? 'Contactez le chef de poste ou les services de la douane'
                  : pirogue && (pirogue.bloquer || !pirogue.est_change)
                  ? 'Cette pirogue est bloquée ou le code secret n\'a pas été changé'
                  : 'Entrez la quantité'
              }
              disabled={connectedStation.bloquer || (pirogue && (pirogue.bloquer || !pirogue.est_change))}
              className="rounded-input"
            />
          </Form.Group>
          <Row className="mb-3">
            <Col>
              <Button
                variant="success"
                onClick={handlePay}
                disabled={connectedStation.bloquer || (pirogue && (pirogue.bloquer || !pirogue.est_change))}
                className="rounded-button"
              >
                Payer
              </Button>
            </Col>
            <Col>
              <Button variant="secondary" onClick={handleClose} className="rounded-button">
                Fermer
              </Button>
            </Col>
          </Row>
          {error && <p className="error-message">{error}</p>}
        </Form>
        <Modal show={showScanner} onHide={() => setShowScanner(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Scanner le QR Code de la carte</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!shouldStopScanner && (
              <QrReader
                onResult={handleResult}
                style={{ width: '100%' }}
              />
            )}
          </Modal.Body>
        </Modal>
        <Modal show={showPinModal} onHide={() => setShowPinModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Entrez le code PIN</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Code PIN</Form.Label>
              <Form.Control
                type="password"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                placeholder="Entrez le code PIN"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowPinModal(false)}>
              Annuler
            </Button>
            <Button variant="primary" onClick={handleConfirmPay}>
              Confirmer
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default ScanPay;
