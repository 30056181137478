import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import '../styles/PopupSubvention.css'; // Assurez-vous d'avoir un fichier CSS adapté

const PopupRemisePoste = ({ show, onHide, connectedPoste, user, subvention, isNewSubvention, onRefresh  }) => {
  const [remise, setRemise] = useState({
    date_demande: '',
    qte: 0,
    etat_actuel: 'NEW',
    date_remise: '',
    nom_poste: ''
  });
  const [equivalentLitres, setEquivalentLitres] = useState(0);

  // Génération d'une chaîne aléatoire de 9 lettres pour la référence
  const generateRandomRef = () => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 0; i < 9; i++) {
      result += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    console.log('nouvelle ref: ', result);
    return result;
  };

  const resetRemiseState = useCallback(() => {
    setRemise({
      ref_demande: generateRandomRef(),
      date_demande: new Date().toISOString().split('T')[0],
      qte: 0,
      etat_actuel: 'NEW',
      date_remise: '',
      nom_poste: connectedPoste ? connectedPoste.nom_poste : ''
    });
  }, [connectedPoste]);


  useEffect(() => {
    if (show) {
      resetRemiseState();
  
      if (isNewSubvention) {
        // Pré-remplir pour une nouvelle subvention
        setRemise(prevState => ({
          ...prevState,
          ref_demande: generateRandomRef(),
          date_demande: new Date().toISOString().split('T')[0],
          qte: 0,
          etat_actuel: 'NEW',
          date_remise: '',
          nom_poste: connectedPoste ? connectedPoste.nom_poste : ''
        }));
      } else if (subvention) {
        // Pré-remplir les champs avec les données de la subvention existante
        setRemise(prevState => ({
          ...prevState,
          ref_demande: subvention.ref_demande,
          date_demande: subvention.date_demande,
          qte: subvention.ticket_dispo || 0,
          etat_actuel: subvention.etat_actuel || 'NEW',
          date_remise: subvention.date_remise,
          nom_poste: subvention.nom_poste
        }));
      }
      // Le cas "else" n'est plus nécessaire car resetRemiseState() s'en charge déjà
    }
  }, [show, isNewSubvention, subvention, connectedPoste, resetRemiseState]);
  useEffect(() => {
    setEquivalentLitres(remise.qte * 10);
  }, [remise.qte]);

   // Gestion de l'ajout d'une nouvelle remise
   const handleAddRemise = async () => {
    if (remise.qte <= 0) return;

    let query =  `INSERT INTO ticket_remis_poste (date_demande, ref_demande, id_poste, qte, etat_actuel, tms_user, id_agent)
      VALUES (CURRENT_DATE, '${remise.ref_demande}', ${connectedPoste.id_poste}, ${remise.qte}, 'NEW', '${user.tms_user}', ${user.id_agent});
 `
    try {
      await envoyerRequeteApi(query, '');
      showToastMessage('Remise ajoutée avec succès', 'success');
      setRemise({ ref_demande: generateRandomRef(), qte: 0 }); // Réinitialiser les champs de saisie et générer une nouvelle référence
      onRefresh();
      onHide();  
    } catch (error) {
      console.error('Error adding remise', error);
      showToastMessage('Erreur lors de l\'ajout de la remise', 'danger');
    }
  };


  const handlePecheUpdate = async () => {
    let query = `UPDATE ticket_remis_poste
      SET qte = ${remise.qte}, etat_actuel = 'OK', date_remise = CURRENT_DATE, id_valideur = ${user.id_agent}
      WHERE ref_demande = '${subvention.ref_demande}'`;
    if (user.code_groupe === 'POSTE') {
        query = `  UPDATE ticket_remis_poste
        SET qte = ${remise.qte}
        WHERE ref_demande = '${subvention.ref_demande}'`;
    }
    console.log('Sauvegarde de la demande: ', query);
    try {
      await envoyerRequeteApi(query, '');
      showToastMessage('Remise approuvée avec succès', 'success');
      
      onRefresh(); // Appeler la fonction de rafraîchissement
      onHide();
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la remise', error);
      showToastMessage('Erreur lors de la mise à jour de la remise', 'danger');
    }
  };
  

  const handleRejeterDemande = async () => {
    let query = `UPDATE ticket_remis_poste
      SET etat_actuel = 'NOK', id_valideur = ${user.id_agent}
      WHERE ref_demande = '${subvention.ref_demande}'`;

    try {
      await envoyerRequeteApi(query, '');
      showToastMessage('Remise rejetée avec succès', 'success');
      onRefresh();
      onHide(); // Fermer le popup après la mise à jour
    } catch (error) {
      console.error('Erreur lors du rejet de la remise', error);
      showToastMessage('Erreur lors du rejet de la remise', 'danger');
    }
  };

  const showToastMessage = useCallback((message, variant) => {
    // Vous pouvez implémenter une fonction de notification ici
  }, []);

  const isPosteGroup = user.code_groupe === 'POSTE';
  const isPecheGroup = user.code_groupe === 'PECHE';
  const isEtatNew = remise.etat_actuel === 'NEW';
  const isEtatOKorNOK = remise.etat_actuel === 'OK' || remise.etat_actuel === 'NOK';

  return (
    <Modal show={show} onHide={onHide} centered className="rounded-popup">
      <Modal.Header closeButton>
        <Modal.Title>{isNewSubvention ? `Nouvelle Remise (${subvention ? subvention.ref_demande : ''})` : `Détails de la remise (${subvention ? subvention.ref_demande : ''})`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-warning rounded-popup">
        <Form>
          <Form.Group controlId="formDateDemande">
            <Form.Label>Date de la demande</Form.Label>
            <Form.Control
              type="text"
              value={remise.date_demande ? new Date(remise.date_demande).toLocaleDateString() : ''}
              readOnly
            />
          </Form.Group>

          <Form.Group controlId="formNomPoste" className="mt-3">
            <Form.Label>Poste</Form.Label>
            <Form.Control
              type="text"
              value={remise.nom_poste}
              readOnly
            />
          </Form.Group>
            
          <Form.Group controlId="formDateRemise" hidden={!subvention || isEtatNew || remise.etat_actuel==='NOK'} className="mt-3">
            <Form.Label>Date de remise</Form.Label>
            <Form.Control
              type="text"
              value={remise.date_remise ? new Date(remise.date_remise).toLocaleDateString() : ''}
              readOnly
              hidden={!subvention}
            />
          </Form.Group>

          <Form.Group controlId="formQte" className="mt-3">
            <Form.Label>Quantité</Form.Label>
            <Form.Control
              type="number"
              placeholder="Entrez la quantité"
              value={remise.qte}
              onChange={(e) => setRemise({ ...remise, qte: parseInt(e.target.value) })}
              disabled={isPosteGroup && !isEtatNew}
            />
            <Form.Text className="text-muted">
              Équivalent en litres : {equivalentLitres} litres
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formEtat" className="mt-3">
            <Form.Label>État</Form.Label>
            <Form.Select
              value={remise.etat_actuel}
              disabled
            >
              <option value="NEW">Nouvelle</option>
              <option value="OK">Approuvée</option>
              <option value="NOK">Rejetée</option>
            </Form.Select>
          </Form.Group>
          {!subvention ? (
  <Button variant="primary" onClick={handleAddRemise} className="mt-4">
    Enregistrer la demande
  </Button>
) : (
  <>
    {isPosteGroup && !isEtatOKorNOK && (
      <Button variant="success" onClick={handlePecheUpdate} className="mt-4">
        Sauvegarder les modifications
      </Button>
    )}
    {isPecheGroup && (
      <>
        {isEtatNew && (
          <Button variant="danger" onClick={handleRejeterDemande} className="mt-4 me-2">
            Rejeter la demande
          </Button>
        )}
        <Button variant="success" onClick={handlePecheUpdate} hidden={isEtatOKorNOK} className="mt-4">
          Approuver la demande
        </Button>
      </>
    )}
  </>
)}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupRemisePoste;
