import React, { useState, useEffect, useContext, useMemo } from 'react';
import {  Spinner, Pagination } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';
import Header from '../Header';
import AddPetrolier from './AddPetrolier';
import PopupStation from './PopupStation';
import DetailComposant from './DetailComposant'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt, faPhone, faGasPump, faCheckCircle, faBan } from '@fortawesome/free-solid-svg-icons';
import '../styles/Petroliers.css';

// Composant pour afficher le spinner de chargement
const LoadingSpinner = ({ message }) => (
  <div className="loading-spinner">
    <Spinner animation="border" size="sm" /> {message}
  </div>
);

// Hook personnalisé pour récupérer la liste des pétroliers
const useFetchPetroliers = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await envoyerRequeteApi('SELECT * FROM liste_petroliers', '');
        setData(response.datas);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return { loading, data, error };
};

// Composant principal pour afficher la liste des pétroliers
const ListPetroliers = () => {
  const { user } = useContext(SycadContext);
  const { loading, data: petroliers, error } = useFetchPetroliers();
  const [petrolierNameFilter, setPetrolierNameFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddPetrolierModal, setShowAddPetrolierModal] = useState(false);
  const [showListStationsModal, setShowListStationsModal] = useState(false);
  const [selectedPetrolierId, setSelectedPetrolierId] = useState(null);
  const [selectedPetrolier, setSelectedPetrolier] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null); 
  const petroliersPerPage = 20;

  // Vérification des permissions utilisateur
  const userHasPermission = (permission) => {
    if (permission === 'ADD_PETROLIER') return user && user.code_groupe === "COMMISSION";
    if (permission === 'DELETE_PETROLIER') return user && user.code_groupe === "COMMISSION";
    return false;
  };

  // Filtrage et pagination des pétroliers
  const currentPetroliers = useMemo(() => {
    let filtered = petroliers;
    if (petrolierNameFilter) {
      filtered = filtered.filter(petrolier => petrolier.nom_petrolier.toLowerCase().includes(petrolierNameFilter.toLowerCase()));
    }
    const indexOfLastPetrolier = currentPage * petroliersPerPage;
    const indexOfFirstPetrolier = indexOfLastPetrolier - petroliersPerPage;
    return filtered.slice(indexOfFirstPetrolier, indexOfLastPetrolier);
  }, [petroliers, petrolierNameFilter, currentPage]);

  const totalPages = useMemo(() => Math.ceil(petroliers.length / petroliersPerPage), [petroliers.length]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleAddPetrolier = () => {
    setSelectedPetrolier(null);
    setShowAddPetrolierModal(true);
  };

  const handleReinitPassword = async (id_agent) => {
    const confirmation = window.confirm('Êtes-vous sûr de vouloir réinitialiser le mot de passe de cet agent ?');
    if (confirmation) {
      try {
        const query = `SELECT * FROM reinit_password($1)`;
        const response = await envoyerRequeteApi(query, [id_agent]);
        if (response && response.datas && response.datas[0]) {
          alert(`Réinitialisation : ${response.datas[0].reinit_password}`);
        }
      } catch (error) {
        console.error('Erreur lors de la réinitialisation du mot de passe', error);
      }
    }
  };

  const handleDeletePetrolier = async (petrolier) => {
    if (petrolier.tot_stations > 0) {
      alert('Impossible de supprimer le pétrolier, il possède encore des stations.');
    } else {
      const confirmation = window.confirm(`Êtes-vous sûr de vouloir supprimer le pétrolier ${petrolier.nom_petrolier}?`);
      if (confirmation) {
        try {
          const query = `DELETE FROM petrolier WHERE id_petrolier = $1`;
          const response = await envoyerRequeteApi(query, [petrolier.id_petrolier]); 
          if (response.success) {
            alert(`Le pétrolier ${petrolier.nom_petrolier} a été supprimé avec succès.`);
          } else {
            alert('Erreur lors de la suppression du pétrolier.');
          }
        } catch (error) {
          console.error('Erreur lors de la suppression du pétrolier', error);
          alert('Erreur lors de la suppression du pétrolier.');
        }
      }
    }
  };

  const handleShowStations = (petrolierId) => {
    setSelectedPetrolierId(petrolierId);
    setShowListStationsModal(true);
  };

  const handleEditPetrolier = (petrolier) => {
    setSelectedPetrolier(petrolier);
    setShowAddPetrolierModal(true);
  };

  const handleStationClick = (station) => {
    setSelectedStation(station);
    setShowListStationsModal(false);
  };

  const handleRetour = () => {
    setSelectedStation(null);
  };

  return (
    <>
      <Header user={user} />
      {!selectedStation ? (
        <div className="container-list-petroliers">
          <h2>Liste des pétroliers</h2>
          <div className="form-container">
            <div className="form-group">
              <label htmlFor="petrolierNameFilter">Filtrer par nom du pétrolier</label>
              <input
                type="text"
                id="petrolierNameFilter"
                value={petrolierNameFilter}
                onChange={(e) => setPetrolierNameFilter(e.target.value)}
              />
            </div>
          </div>
          <Pagination className="justify-content-center">
            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
          </Pagination>
          <div className="text-center mb-3">
            <strong>Il y a {petroliers.length} pétroliers</strong>
            <button
              className="bouton-nouveau"
              onClick={handleAddPetrolier}
              disabled={!userHasPermission('ADD_PETROLIER')}
              hidden={!userHasPermission('ADD_PETROLIER')}
            >
              Nouveau pétrolier
            </button>
          </div>
          <div className="petroliers-container">
            {loading ? (
              <LoadingSpinner message="Veuillez patienter pendant le chargement des pétroliers." />
            ) : error ? (
              <p className="error-message">Erreur lors du chargement des pétroliers : {error.message}</p>
            ) : (
              currentPetroliers.map((petrolier) => (
                <div key={petrolier.id_petrolier} className="petrolier-card">
                  <img className="petrolier-img" src={`${process.env.PUBLIC_URL}/images/userprofil.png`} alt="Logo petrolier" />
                  <h3>{petrolier.nom_petrolier}</h3>
                  <p><FontAwesomeIcon icon={faMapMarkerAlt} /> <strong>Adresse:</strong> {petrolier.adresse}</p>
                  <p><FontAwesomeIcon icon={faPhone} /> <strong>Téléphone:</strong> {petrolier.telephone}</p>
                  <p><FontAwesomeIcon icon={faEnvelope} /> <strong>Email:</strong> {petrolier.email}</p>
                  <p><FontAwesomeIcon icon={faGasPump} /> <strong>Nbre stations:</strong> {petrolier.tot_stations}</p>
                  <p>
                    <FontAwesomeIcon icon={petrolier.actif ? faCheckCircle : faBan} color={petrolier.actif ? 'green' : 'red'} /> 
                    <strong> Actif:</strong> {petrolier.actif ? 'Oui' : 'Non'}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={petrolier.bloquer ? faBan : faCheckCircle} color={petrolier.bloquer ? 'red' : 'green'} /> 
                    <strong> Bloqué:</strong> {petrolier.bloquer ? 'Oui' : 'Non'}
                  </p>
                  <div className="button-group">
                    <button className="rounded-button btn-primary" hidden={!userHasPermission('ADD_PETROLIER')} onClick={() => handleEditPetrolier(petrolier)}>Afficher</button>
                    <button className="rounded-button btn-primary" onClick={() => handleShowStations(petrolier.id_petrolier)}>Stations</button>
                    {userHasPermission('DELETE_PETROLIER') && (
                      <>
                        <button className="rounded-button btn-success" onClick={() => handleReinitPassword(petrolier.id_petrolier)}>
                          Réinitialiser MDP
                        </button>
                        <button className="rounded-button btn-danger" onClick={() => handleDeletePetrolier(petrolier)}>
                          Supprimer
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      ) : (
        <DetailComposant
          station={selectedStation}
          onRetour={handleRetour}
          user={user}
        />
      )}

      <AddPetrolier 
        show={showAddPetrolierModal} 
        onHide={() => setShowAddPetrolierModal(false)} 
        petrolier={selectedPetrolier} 
        onSave={() => window.location.reload()} // Rafraîchir la liste après sauvegarde
      />

      <PopupStation 
        show={showListStationsModal} 
        onHide={() => setShowListStationsModal(false)} 
        petrolierId={selectedPetrolierId} 
        onStationClick={handleStationClick} 
      />
    </>
  );
};

export default ListPetroliers;
