import React, { useState, useContext } from 'react';
import { getReponseApi } from './apis/api_backend';
import { useNavigate } from 'react-router-dom';
import './styles/Login.css';
import { SycadContext } from '../contexts/SycadContext';
import { AuthContext } from '../contexts/AuthContext';
import Header from './Header';

const Login = () => {
  const [login_agent, setlogin_agent] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { setUser, setAppParams, setConnectedStation, setConnectedPoste, setConnectedPetrolier, setIsAdmin } = useContext(SycadContext);
  const { login } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    try {
      const response = await getReponseApi('/api/agents/login', 'POST', {
        login_agent,
        password
      });

      if (!response) {
        setErrorMessage("Erreur lors de la connexion. Veuillez vérifier vos identifiants.");
        return;
      }
      
      const { token, user, sousGroupe } = response;

      // Sauvegarder le token et l'utilisateur
      localStorage.setItem('token', token);
      login(user);
      setUser(user);

      const appParamsLoaded = await loadAppParams(1);
      if (!appParamsLoaded) {
        setErrorMessage("L'accès est bloqué pour cette agence.");
        return;
      }

      if (user) {
        if (['ADMIN', 'SADMIN', 'COMMISSION', 'DOUANE', 'PECHE'].includes(user.code_groupe)) {
          redirectUser(user.code_groupe);
        } else if (sousGroupe && sousGroupe.actif) {
          const success = await afficherUser(user, sousGroupe);
          if (success) {
            redirectUser(user.code_groupe);
          } else {
            setErrorMessage("Votre demande de connexion a échoué. Veuillez vérifier vos identifiants.");
          }
        } else {
          setErrorMessage("Votre demande de connexion a échoué. Veuillez vérifier vos identifiants.");
        }
      }
    } catch (error) {
      setErrorMessage("Erreur lors de la connexion. Veuillez réessayer.");
    }
  };

  const redirectUser = (codeGroupe) => {
    const routes = {
      STATION: '/station',
      POSTE: '/poste',
      SADMIN: '/systeme',
      PECHE: '/peche',
      DOUANE: '/douane',
      PETROLIER: '/petrolier',
      COMMISSION: '/petrolier',
      ADMIN: '/admin'
    };
    navigate(routes[codeGroupe] || '/admin');
  };

  const loadAppParams = async (pid_agence) => {
    try {
      const response = await getReponseApi(`/api/agents/params/${pid_agence}`, 'GET');
      const params = response[0]?.get_params;

      if (params?.nom_app) {
        setAppParams({
          List_Groupes: params.list_groupes,
          List_Regions: params.list_regions,
          List_Etats: params.list_etats,
          List_Cartes: params.list_cartes,
          List_Stations: params.list_stations,
          List_Petroliers: params.list_petroliers,
          List_Postes: params.list_postes,
        });
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error loading app params", error);
      return false;
    }
  };

  const afficherUser = async (user, sousGroupe) => {
    const isAdmin = [1, 2, 3, 4, 15].includes(user.id_groupe);
    setIsAdmin(isAdmin);

    switch (user.code_groupe) {
      case "STATION":
        if (sousGroupe?.actif) {
          setConnectedStation(sousGroupe);
          return true;
        }
        break;
      case "POSTE":
        if (sousGroupe?.actif) {
          setConnectedPoste(sousGroupe);
          return true;
        }
        break;
      case "PETROLIER":
        if (sousGroupe?.actif) {
          setConnectedPetrolier(sousGroupe);
          return true;
        }
        break;
      default:
        return false;
    }
    return false;
  };

  return (
    <>
      <Header />
      <div className="login-background">
        <div className="wave-animation"></div>
        <div className="login-container">
          <div className="login-card">
            <img className="login-card-image" src={`${process.env.PUBLIC_URL}/images/user-icon.png`} alt="User Icon" />
            <div className="login-card-body">
              <h2 className="text-center">Connexion</h2>
              {errorMessage && <p className="text-danger">{errorMessage}</p>}
              <form onSubmit={handleLogin}>
                <div className="loginform-group">
                  <label className="loginlabel">Nom d'utilisateur</label>
                  <input
                    type="text"
                    className="logininput"
                    placeholder="Entrez votre nom d'utilisateur"
                    value={login_agent}
                    onChange={(e) => setlogin_agent(e.target.value)}
                    required
                  />
                  <label className="loginlabel">Mot de passe</label>
                  <input
                    type="password"
                    className="logininput"
                    placeholder="Mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="loginrounded-button">
                  Se connecter
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
