import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Container, Form, Row, Col, FloatingLabel, Pagination,Spinner, Table } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';
import Header from '../Header';
import '../styles/ListVentes.css';

const ListVentesStations = () => {
  const { user, connectedPetrolier } = useContext(SycadContext);
  
  const [filteredVentesStations, setFilteredVentesStations] = useState([]);
  const [departements, setDepartements] = useState([]);
  const [petroliers, setPetroliers] = useState([]);
  const [stations, setStations] = useState([]);
  const [selectedDepartement, setSelectedDepartement] = useState('');
  const [selectedPetrolier, setSelectedPetrolier] = useState('');
  const [selectedStation, setSelectedStation] = useState('');
  const [dateDebut, setDateDebut] = useState('');
  const [dateFin, setDateFin] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [ticketsDispo, setTicketsDispo] = useState(0);
  const ventesStationsPerPage = 70;

  useEffect(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];

    setDateDebut(firstDayOfMonth);
    setDateFin(lastDayOfMonth);
  }, []);



  const fetchTicketsDispo = useCallback(async () => {
    try {
      const query = `select sum(qte) as tickets_dispo from ticket_carb tc`;
      const response = await envoyerRequeteApi(query, '');
      setTicketsDispo(response.datas[0].tickets_dispo);
    } catch (error) {
      console.error('Error fetching tickets dispo', error);
    }
  }, []);

  useEffect(() => {
    fetchTicketsDispo();
    const interval = setInterval(fetchTicketsDispo, 30000); // 30 secondes
    return () => clearInterval(interval);
  }, [fetchTicketsDispo]);
  
  const fetchVentesStations = useCallback(async () => {
    setIsLoading(true);
    setFilteredVentesStations([]); // Vider le tableau avant de charger les nouvelles données

    const dateDebutFormatted = dateDebut.replace(/-/g, '');
    const dateFinFormatted = dateFin.replace(/-/g, '');

    let query = `SELECT sum(qte) as qte, matricule, date_mouv, id_localite, nom_localite, nom_departement, nom_station, id_petrolier, nom_petrolier, code_station 
      FROM v_vente 
      WHERE date_mouv BETWEEN '${dateDebutFormatted}' AND '${dateFinFormatted}'
    `;

    if (selectedDepartement) {
      query += ` AND nom_departement = '${selectedDepartement}'`;
    }
    if (selectedPetrolier) {
      query += ` AND id_petrolier = ${selectedPetrolier}`;
    }
    if (selectedStation) {
      query += ` AND code_station = '${selectedStation}'`;
    }
    if (user.code_groupe === 'PETROLIER') {
      query += ` AND id_petrolier = ${user.id_sgroupe}`;
    }
    
    query += ` GROUP BY id_localite, matricule, date_mouv, id_localite, nom_localite, nom_departement, nom_station, id_petrolier, nom_petrolier, code_station`;

    if (user.code_groupe === 'POSTE') {
      query += ` AND id_localite = ${user.id_localite}`;
    }

    console.log('Fetching VentesStations with query:', query);

    try {
      const response = await envoyerRequeteApi(query, '');
      setFilteredVentesStations(response.datas);
    } catch (error) {
      console.error('Error fetching VentesStations', error);
      setFilteredVentesStations([]); // En cas d'erreur, s'assurer que le tableau est vide
    } finally {
      setIsLoading(false);
    }
  }, [dateDebut, dateFin, selectedDepartement, selectedPetrolier, selectedStation, user]);

  const fetchDepartements = useCallback(async () => {
    const query = `SELECT DISTINCT nom_departement FROM liste_localites ORDER BY nom_departement`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setDepartements(response.datas);
    } catch (error) {
      console.error('Error fetching departements', error);
    }
  }, []);

  const fetchPetroliers = useCallback(async () => {
    let query = `SELECT DISTINCT v.id_petrolier, v.nom_petrolier FROM v_vente v`;
    if (selectedDepartement) {
      query += ` WHERE v.nom_departement = '${selectedDepartement}'`;
    }
    if (user.code_groupe === 'PETROLIER') {
      let snom_petrolier = connectedPetrolier.nom_petrolier;
      //delete spaces
      snom_petrolier = snom_petrolier.trimEnd();
      if (!query.includes('WHERE')) {
        query += ` WHERE v.nom_petrolier = '${snom_petrolier}'`;
      } else {
        query += ` AND v.nom_petrolier = '${snom_petrolier}'`;
    }
    }
    query += ` ORDER BY v.nom_petrolier`;
    console.log('Fetching petroliers with query:', query);
    try {
      const response = await envoyerRequeteApi(query, '');
      setPetroliers(response.datas);
    } catch (error) {
      console.error('Error fetching petroliers', error);
    }
  }, [selectedDepartement, connectedPetrolier, user.code_groupe]);

  const fetchStations = useCallback(async () => {
    let query = `SELECT DISTINCT v.code_station, v.nom_station FROM v_vente v WHERE 1=1`;
    if (selectedDepartement) {
      query += ` AND v.nom_departement = '${selectedDepartement}'`;
    }
    if (selectedPetrolier) {
      query += ` AND v.id_petrolier = ${selectedPetrolier}`;
    }
    if (user.code_groupe === 'PETROLIER') {
      let snom_petrolier = connectedPetrolier.nom_petrolier;
      snom_petrolier = snom_petrolier.trimEnd();
      query += ` AND v.nom_petrolier = '${snom_petrolier}'`;
    
    }
    query += ` ORDER BY v.nom_station`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setStations(response.datas);
    } catch (error) {
      console.error('Error fetching stations', error);
    }
  }, [selectedDepartement, selectedPetrolier,user.code_groupe, connectedPetrolier]);

  useEffect(() => {
    fetchDepartements();
  }, [fetchDepartements]);

  useEffect(() => {
    fetchPetroliers();
  }, [fetchPetroliers, selectedDepartement]);

  useEffect(() => {
    fetchStations();
  }, [fetchStations, selectedDepartement, selectedPetrolier]);

  useEffect(() => {
    if (dateDebut && dateFin) {
      fetchVentesStations();
    }
  }, [dateDebut, dateFin, fetchVentesStations, selectedDepartement, selectedPetrolier, selectedStation]);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDateChange = (setDateFunc) => (e) => {
    setDateFunc(e.target.value);
  };

  const handleDepartementChange = (e) => {
    setSelectedDepartement(e.target.value);
    setSelectedPetrolier('');
    setSelectedStation('');
    setCurrentPage(1); // Réinitialiser la pagination
  };
  const handlePetrolierChange = (e) => {
    setSelectedPetrolier(e.target.value);
    setSelectedStation('');
    setCurrentPage(1); // Réinitialiser la pagination
  };

  const indexOfLastVente = currentPage * ventesStationsPerPage;
  const indexOfFirstVente = indexOfLastVente - ventesStationsPerPage;
  const currentVentesStations = filteredVentesStations.slice(indexOfFirstVente, indexOfLastVente);

  const totalVentesStations = filteredVentesStations.length;
  const totalQteVendue = filteredVentesStations.reduce((acc, vente) => acc + vente.qte, 0);

  const totalPages = Math.ceil(filteredVentesStations.length / ventesStationsPerPage);

  return (
    <>
      <Header user={user} />
      <Container className="mt-4 container-list-ventes-stations">
        <h2>Liste des Ventes des stations</h2>
        <div className="tickets-dispo-circle">
          <span className="tickets-dispo-number">{ticketsDispo}</span>
          <span className="tickets-dispo-label">Tickets disponibles</span>
        </div>
        <Form className="mb-4">
          <Row>
            <Col>
              <FloatingLabel controlId="dateDebut" label="Date Début">
                <Form.Control
                  type="date"
                  value={dateDebut}
                  onChange={handleDateChange(setDateDebut)}
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="dateFin" label="Date Fin">
                <Form.Control
                  type="date"
                  value={dateFin}
                  onChange={handleDateChange(setDateFin)}
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="departementSelect" label="Département">
                <Form.Select
                  value={selectedDepartement}
                  onChange={handleDepartementChange}
                >
                  <option value="">Tous les départements</option>
                  {departements.map((dept, index) => (
                    <option key={index} value={dept.nom_departement}>
                      {dept.nom_departement}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="petrolierSelect" label="Pétrolier">
                <Form.Select
                  value={selectedPetrolier}
                  onChange={handlePetrolierChange}
                >
                  <option value="">Tous les pétroliers</option>
                  {petroliers.map((petrolier, index) => (
                    <option key={index} value={petrolier.id_petrolier}>
                      {petrolier.nom_petrolier}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="stationSelect" label="Station">
                <Form.Select
                  value={selectedStation}
                  onChange={(e) => setSelectedStation(e.target.value)}
                >
                  <option value="">Toutes les stations</option>
                  {stations.map((station, index) => (
                    <option key={index} value={station.code_station}>
                      {station.nom_station}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
        </Form>
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
        <div className="text-center mb-3">
          <strong>Nombre de ventes : {totalVentesStations}</strong>
          <br />
          <strong>Quantité totale vendue : {totalQteVendue} Tkts soit {totalQteVendue * 10}L</strong>
        </div>
        {isLoading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Chargement...</span>
            </Spinner>
          </div>
        ) : (
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Département</th>
                <th>Pétrolier</th>
                <th>Station</th>
                <th>Quantité</th>
                <th>Matricule</th>
              </tr>
            </thead>
            <tbody>
              {currentVentesStations.map((vente, index) => (
                <tr key={index}>
                  <td>{new Date(vente.date_mouv).toLocaleDateString()}</td>
                  <td>{vente.nom_departement}</td>
                  <td>{vente.nom_petrolier}</td>
                  <td>{vente.nom_station}</td>
                  <td>{vente.qte} Tkts soit {vente.qte * 10}L</td>
                  <td>{vente.matricule}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Container>
    </>
  );
};

export default ListVentesStations;