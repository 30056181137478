import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Toast, Spinner, Pagination, Modal,Alert } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';
import Header from '../Header';
import PopupSubvention from './PopupSubvention';
import PopupPecheur from './PopupPecheur';
import ListBlocages from './ListBlocages';
import AffecterPirogue from './AffecterPirogue'; // Import du nouveau composant
import '../styles/Pirogues.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGasPump, faPhone, faShip, faCheckCircle, faBan } from '@fortawesome/free-solid-svg-icons';

const Pirogues = () => {
  const [showAlert, setShowAlert] = useState(false);
  const { user, connectedPoste } = useContext(SycadContext);
  const [pirogues, setPirogues] = useState([]);
  const [filteredPirogues, setFilteredPirogues] = useState([]);
  const [regions, setRegions] = useState([]);
  const [allPostes, setAllPostes] = useState([]);
  const [filteredPostes, setFilteredPostes] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedPoste, setSelectedPoste] = useState('');
  const [matriculeFilter, setMatriculeFilter] = useState('');
  const [telephoneFilter, setTelephoneFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showToast, setShowToast] = useState(true);
  const [showSubventionPopup, setShowSubventionPopup] = useState(false);
  const [showPecheurPopup, setShowPecheurPopup] = useState(false);
  const [showBlocageList, setShowBlocageList] = useState(false);
  const [showAffecterPopup, setShowAffecterPopup] = useState(false); // State pour afficher AffecterPirogue
  const [selectedPirogue, setSelectedPirogue] = useState(null);
  const piroguesPerPage = 20;

  useEffect(() => {
    if (user.code_groupe === 'POSTE' && connectedPoste) {
      setSelectedRegion(connectedPoste.code_region || '');
      setSelectedPoste(connectedPoste.code_poste || '');
    }
  }, [user, connectedPoste]);

  const fetchPirogues = useCallback(async () => {
    setShowToast(true);
    let query = `SELECT * FROM liste_pirogue`;
    
    if (user.code_groupe === 'POSTE') {
      query += ` WHERE btrim(code_poste) = '${connectedPoste?.code_poste}'`;
    }
    try {
      const response = await envoyerRequeteApi(query, '');
      setPirogues(response.datas);
      setFilteredPirogues(response.datas);
    } catch (error) {
      console.error('Error fetching pirogues', error);
    } finally {
      setShowToast(false);
    }
  }, [user.code_groupe, connectedPoste]);

  const fetchRegions = useCallback(async () => {
    let query = `SELECT DISTINCT a_region FROM liste_pirogue`;
    if (user.code_groupe === 'POSTE') {
      query += ` WHERE btrim(code_poste) = '${connectedPoste?.code_poste}'`;
    }
    try {
      const response = await envoyerRequeteApi(query, '');
      setRegions(response.datas);
    } catch (error) {
      console.error('Error fetching regions', error);
    }
  }, [user.code_groupe, connectedPoste]);

  const handleRegionChange = (e) => {
    const newRegion = e.target.value;
    
    setSelectedRegion(newRegion);
    setSelectedPoste('');
  };

  const fetchPostes = useCallback(async () => {
    let query = `SELECT DISTINCT btrim(code_poste) as code_poste, nom_poste, a_region FROM liste_pirogue`;
    if (user.code_groupe === 'POSTE') {
      query += ` WHERE btrim(code_poste) = '${connectedPoste?.code_poste}'`;
    }
    try {
      const response = await envoyerRequeteApi(query, '');
      setAllPostes(response.datas);
      setFilteredPostes(response.datas);
    } catch (error) {
      console.error('Error fetching postes', error);
    }
  }, [user.code_groupe, connectedPoste]);

  useEffect(() => {
    fetchPirogues();
    fetchRegions();
    fetchPostes();
  }, [fetchPirogues, fetchRegions, fetchPostes]);

  useEffect(() => {
    if (selectedRegion) {
      const postesForRegion = allPostes.filter(poste => poste.a_region === selectedRegion);
      setFilteredPostes(postesForRegion);
      if (selectedPoste && !postesForRegion.some(poste => poste.code_poste === selectedPoste)) {
        setSelectedPoste('');
      }
    } else {
      setFilteredPostes(allPostes);
    }
  }, [selectedRegion, allPostes, selectedPoste]);
  const applyFilters = useCallback(() => {
    let filtered = pirogues;

    if (selectedRegion) {
      filtered = filtered.filter(pirogue => pirogue.a_region === selectedRegion);
    }
    if (selectedPoste) {
      filtered = filtered.filter(pirogue => pirogue.code_poste === selectedPoste);
    }
    if (matriculeFilter) {
      filtered = filtered.filter(pirogue => pirogue.matricule.includes(matriculeFilter));
    }
    if (telephoneFilter) {
      filtered = filtered.filter(pirogue => pirogue.telephone.includes(telephoneFilter));
    }

    setFilteredPirogues(filtered);
    setCurrentPage(1);
  }, [pirogues, selectedRegion, selectedPoste, matriculeFilter, telephoneFilter]);
  const handleBlocageList = (pirogue) => {
    setSelectedPirogue(pirogue);
    setShowBlocageList(true);
  };

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const handleAffecter = (pirogue) => {
    if (user.mdp_changer === false) {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 5000); // L'alerte disparaît après 5 secondes
    } else { 
      setSelectedPirogue(pirogue);
    setShowAffecterPopup(true);
     
    }
   
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastPirogue = currentPage * piroguesPerPage;
  const indexOfFirstPirogue = indexOfLastPirogue - piroguesPerPage;
  const currentPirogues = filteredPirogues.slice(indexOfFirstPirogue, indexOfLastPirogue);

  const totalPages = Math.ceil(filteredPirogues.length / piroguesPerPage);

  return (
    <>
      <Header user={user} />
      <div className="container-list-pirogues">
        <h2>Liste des pirogues</h2>
        <div className="form-container">
          <div className="form-group">
            <label htmlFor="regionSelect">Région</label>
            <select
              id="regionSelect"
              value={selectedRegion}
              onChange={handleRegionChange}
              disabled={user.code_groupe === 'POSTE'}
            >
              <option value="">Toutes les régions</option>
              {regions.map((region, index) => (
                <option key={index} value={region.a_region}>
                  {region.a_region}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="posteSelect">Poste</label>
            <select
              id="posteSelect"
              value={selectedPoste}
              onChange={(e) => setSelectedPoste(e.target.value)}
              disabled={user.code_groupe === 'POSTE'}
            >
              <option value="">Tous les postes</option>
              {filteredPostes.map((poste, index) => (
                <option key={index} value={poste.code_poste}>
                  {poste.nom_poste}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="matriculeFilter">Matricule</label>
            <input
              type="text"
              id="matriculeFilter"
              value={matriculeFilter}
              onChange={(e) => setMatriculeFilter(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="telephoneFilter">Téléphone</label>
            <input
              type="text"
              id="telephoneFilter"
              value={telephoneFilter}
              onChange={(e) => setTelephoneFilter(e.target.value)}
            />
          </div>
          <div className="button-group">
            <button className="rounded-button btn-primary" onClick={applyFilters}>
              Filtrer
            </button>
          </div>
        </div>
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
        <div className="text-center mb-3">
          <strong>Il y a {filteredPirogues.length} pirogues</strong>
        </div>
        <div className="pirogues-container">
          {currentPirogues.map((pirogue) => (
            <div key={pirogue.matricule} className="pirogue-card">
              <img className="pirogue-img" src={`${process.env.PUBLIC_URL}/images/pirogue-img.png`} alt="pirogue ico" />
              <h3>{pirogue.matricule}</h3>
              
              <p><FontAwesomeIcon icon={faShip} /> Nom Pirogue: {pirogue.nom_pirogue}</p>
              <p>
                <FontAwesomeIcon icon={faGasPump} /> Quota : {pirogue.quota} 
                {pirogue.quota === 0 ? 'Épuisé' : 
                pirogue.quota === 1 ? 'Litre' : 
                'Litres'}
              </p>
              <p><FontAwesomeIcon icon={faPhone} /> Tél. Propriétaire: {pirogue.telephone}</p>
              <p><FontAwesomeIcon icon={faPhone} /> Tél.Pêcheur: {pirogue.tel_pecheur}</p>
              <p>
                  <FontAwesomeIcon icon={pirogue.bloquer ? faBan : faCheckCircle} color={pirogue.bloquer ? 'red' : 'green'} /> 
                  <strong> Bloqué:</strong> {pirogue.bloquer ? 'Oui' : 'Non'}
                </p>
                <p>
                <FontAwesomeIcon icon={pirogue.est_change ? faBan : faCheckCircle} color={pirogue.est_change ? 'green' : 'red'} /> 
                <strong> Code changé :</strong> {pirogue.est_change ? 'Oui' : 'Non'}
              </p>
              <div className="button-group">
                <button 
                  className="rounded-button btn-info" 
                  onClick={() => {
                    setSelectedPirogue(pirogue);
                    setShowSubventionPopup(true);
                  }}
                >
                  Subventions
                </button>
                <button 
                  className="rounded-button btn-info" 
                  onClick={() => {
                    setSelectedPirogue(pirogue);
                    setShowPecheurPopup(true);
                  }}
                >
                  Pêcheurs
                </button>
                
                <button 
                  className="rounded-button btn-warning" 
                  onClick={() => handleAffecter(pirogue)}
                >
                  Changement Port
                </button>
                {showAlert && (
          <Alert variant="warning" className="mt-2">
            Veuillez changer votre mot de passe avant de changer le port de cette pirogue.
          </Alert>
        )}
                <button 
                  className="rounded-button btn-danger" 
                  onClick={() => handleBlocageList(pirogue)}  // Afficher la liste des blocages
                >
                  {pirogue.bloquer ? 'Voir Blocages' : 'Bloquer'}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide style={{ position: 'absolute', top: 20, right: 20 }}>
        <Toast.Header>
          <strong className="me-auto">Chargement</strong>
        </Toast.Header>
        <Toast.Body>
          <Spinner animation="border" size="sm" /> Veuillez patienter pendant le chargement des pirogues.
        </Toast.Body>
      </Toast>
      <PopupSubvention 
        show={showSubventionPopup} 
        onHide={() => setShowSubventionPopup(false)} 
        pirogue={selectedPirogue} 
      />
       <PopupPecheur 
        show={showPecheurPopup} 
        onHide={() => setShowPecheurPopup(false)} 
        pirogue={selectedPirogue} 
      />
      <PopupPecheur 
        show={showPecheurPopup} 
        onHide={() => setShowPecheurPopup(false)} 
        pirogue={selectedPirogue} 
      />
      <AffecterPirogue
              pirogue={selectedPirogue}
			   show={showAffecterPopup}
              onHide={() => setShowAffecterPopup(false)}
              onSave={fetchPirogues} 
            />
      {selectedPirogue && (
        <Modal
          show={showBlocageList}
          onHide={() => setShowBlocageList(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Historiques blocages : {selectedPirogue?.matricule}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListBlocages
              pirogue={selectedPirogue}
              show={showBlocageList}
              onHide={() => setShowBlocageList(false)}
              onSave={fetchPirogues}
            />
          </Modal.Body>
        </Modal>
      )}
      
    </>
  );
};

export default Pirogues;
