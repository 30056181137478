import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Container, Form, Row, Col, FloatingLabel, Pagination, Table } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';
import Header from '../Header';
import '../styles/ListVentes.css'; // Le style est identique à ListVentes

const ListConsommations = () => {
  const { user,connectedPoste } = useContext(SycadContext);
  const [consommations, setConsommations] = useState([]);
  const [filteredConsommations, setFilteredConsommations] = useState([]);
  const [localites, setLocalites] = useState([]);
  const [postes, setPostes] = useState([]);
  const [selectedLocalite, setSelectedLocalite] = useState('0');
  const [selectedPoste, setSelectedPoste] = useState('');
  const [matriculeFilter, setMatriculeFilter] = useState('');
  const [dateDebut, setDateDebut] = useState('');
  const [dateFin, setDateFin] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const consommationsPerPage = 20;

  useEffect(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];

    setDateDebut(firstDayOfMonth);
    setDateFin(lastDayOfMonth);
  }, []);

  const fetchConsommations = useCallback(async () => {
    const dateDebutFormatted = dateDebut.replace(/-/g, '');
    const dateFinFormatted = dateFin.replace(/-/g, '');

    let query = `SELECT sum(qte) as qte, matricule, date_mouv, id_localite, nom_localite, nom_departement, nom_station,code_poste,nom_poste 
      FROM v_vente vv 
      WHERE vv.date_mouv BETWEEN '${dateDebutFormatted}' AND '${dateFinFormatted}'
      AND (${matriculeFilter ? `'${matriculeFilter}'` : 'NULL'} IS NULL OR vv.matricule = '${matriculeFilter}')
      AND (${selectedLocalite || 0} = 0 OR vv.id_localite = ${selectedLocalite})      
    `;

    if (user.code_groupe === 'POSTE') {
      query += ` AND vv.id_localite = ${connectedPoste.id_localite}`;
    }
    query += ` GROUP BY id_localite, matricule, date_mouv, id_localite, nom_localite, nom_departement,nom_station,nom_poste,code_poste`;
    console.log('Fetching consommations with query:', query);
   
    try {
      const response = await envoyerRequeteApi(query, '');
      setConsommations(response.datas);
      setFilteredConsommations(response.datas);
      console.log('Fetched consommations:', response.datas);
    } catch (error) {
      console.error('Error fetching consommations', error);
    }
  }, [dateDebut, dateFin, matriculeFilter, selectedLocalite, user, connectedPoste]);

  const fetchLocalites = useCallback(async () => {
    const query = `SELECT id_localite, btrim(concat(nom_departement,': ',nom_localite)) as nom_localite FROM liste_localites ll ORDER BY nom_departement`;
    console.log('Fetching localites with query:', query);

    try {
      const response = await envoyerRequeteApi(query, '');
      setLocalites(response.datas);
      console.log('Fetched localites:', response.datas);
    } catch (error) {
      console.error('Error fetching localites', error);
    }
  }, []);

  const fetchPostes = useCallback(async () => {
    const query = `SELECT btrim(code_poste) as code_poste, nom_poste FROM poste_regional ORDER BY nom_poste`;
    console.log('Fetching postes with query:', query);

    try {
      const response = await envoyerRequeteApi(query, '');
      setPostes(response.datas);
      console.log('Fetched postes:', response.datas);
    } catch (error) {
      console.error('Error fetching postes', error);
    }
  }, []);

  useEffect(() => {
    if (dateDebut && dateFin) {
      fetchConsommations();
    }
    fetchLocalites();
    fetchPostes();
  }, [dateDebut, dateFin, fetchConsommations, fetchLocalites, fetchPostes]);

  const applyFilters = useCallback(() => {
    let filtered = consommations;

    if (selectedLocalite && selectedLocalite !== '0') {
      filtered = filtered.filter(consommation => consommation.id_localite === parseInt(selectedLocalite));
    }
    if (selectedPoste) {
      filtered = filtered.filter(consommation => consommation.code_poste === selectedPoste);
    }
    if (matriculeFilter) {
      filtered = filtered.filter(consommation => consommation.matricule.includes(matriculeFilter));
    }

    setFilteredConsommations(filtered);
    console.log('Applied filters:', { selectedLocalite, selectedPoste, matriculeFilter });
  }, [consommations, selectedLocalite, selectedPoste, matriculeFilter]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    console.log('Page changed to:', pageNumber);
  };

  const handleDateChange = (setDateFunc) => (e) => {
    setDateFunc(e.target.value);
    fetchConsommations();
  };

  const indexOfLastConsommation = currentPage * consommationsPerPage;
  const indexOfFirstConsommation = indexOfLastConsommation - consommationsPerPage;
  const currentConsommations = filteredConsommations.slice(indexOfFirstConsommation, indexOfLastConsommation);

  const totalConsommations = filteredConsommations.length;
  const totalQteConsommee = filteredConsommations.reduce((acc, consommation) => acc + consommation.qte, 0);

  const totalPages = Math.ceil(filteredConsommations.length / consommationsPerPage);

  return (
    <>
      <Header user={user} />
      <Container className="mt-4 container-list-consommations">
        <h2>Liste des Consommations</h2>
        <Form className="mb-4">
          <Row>
            <Col>
              <FloatingLabel controlId="dateDebut" label="Date Début">
                <Form.Control
                  type="date"
                  value={dateDebut}
                  onChange={handleDateChange(setDateDebut)}
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="dateFin" label="Date Fin">
                <Form.Control
                  type="date"
                  value={dateFin}
                  onChange={handleDateChange(setDateFin)}
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="localiteSelect" label="Localité">
                <Form.Select
                  aria-label="Localité"
                  value={selectedLocalite}
                  onChange={(e) => setSelectedLocalite(e.target.value)}
                >
                  <option value="0">Toutes les localités</option>
                  {localites.map((localite, index) => (
                    <option key={index} value={localite.id_localite}>
                      {localite.nom_localite}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="posteSelect" label="Poste">
                <Form.Select
                  aria-label="Poste"
                  value={selectedPoste}
                  onChange={(e) => setSelectedPoste(e.target.value)}
                >
                  <option value="">Tous les postes</option>
                  {postes.map((poste, index) => (
                    <option key={index} value={poste.code_poste}>
                      {poste.nom_poste}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="matriculeFilter" label="Matricule">
                <Form.Control
                  type="text"
                  value={matriculeFilter}
                  onChange={(e) => setMatriculeFilter(e.target.value)}
                />
              </FloatingLabel>
            </Col>
          </Row>
        </Form>
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
        <div className="text-center mb-3">
          <strong>Nombre de consommations : {totalConsommations}</strong>
          <br />
          <strong>Quantité totale consommée : {totalQteConsommee} Tkts soit {totalQteConsommee * 10}L</strong>
        </div>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th className="d-none d-md-table-cell">Localité</th>
              <th>Quantité</th>
              <th>Matricule</th>
             
              <th className="d-none d-md-table-cell">Département</th>
            </tr>
          </thead>
          <tbody>
            {currentConsommations.map((consommation) => (
              <tr key={consommation.matricule + consommation.date_mouv}>
                <td>{new Date(consommation.date_mouv).toLocaleDateString()}</td>
                <td className="d-none d-md-table-cell">{consommation.nom_poste}</td>
                <td>{consommation.qte} Tkts soit {consommation.qte * 10}L</td>
                <td>{consommation.matricule}</td>
               
                <td className="d-none d-md-table-cell">{consommation.nom_departement}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default ListConsommations;
