// ListSubventions.js
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Container, Form, Button, Row, Col, FloatingLabel, Pagination, Table,Alert } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';
import Header from '../Header';
import AddSubvention from './AddSubvention';
import '../styles/ListSubventions.css';

const ListSubventions = () => {
  const [showAlert, setShowAlert] = useState(false);
  const { user, connectedPoste } = useContext(SycadContext);
  const [ListSubventions, setSubventions] = useState([]);
  const [filteredSubventions, setFilteredSubventions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [postes, setPostes] = useState([]);
  const [profils, setProfils] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedPoste, setSelectedPoste] = useState('');
  const [selectedProfil, setSelectedProfil] = useState('');
  const [matriculeFilter, setMatriculeFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddSubvention, setShowAddSubvention] = useState(false);
  const [ticketsDispo, setTicketsDispo] = useState(0); // État pour les tickets disponibles
  const [ticketsRemis, setTicketsRemis] = useState(0); // État pour les tickets disponibles
  const subventionsPerPage = 20;

  useEffect(() => {
    if (user.code_groupe === 'POSTE' && connectedPoste) {
      setSelectedRegion(connectedPoste.code_region || '');
      setSelectedPoste(connectedPoste.code_poste || '');
    }
  }, [user, connectedPoste]);

  const handleNewSubventionClick = () => {
    if (user.mdp_changer === false) {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 5000); // L'alerte disparaît après 5 secondes
    } else {
      setShowAddSubvention(true);
    }
  };

  const fetchSubventions = useCallback(async () => {
    let query = `SELECT * FROM list_subventions`;

    if (user.code_groupe === 'POSTE') {
      query += ` WHERE id_poste = '${connectedPoste?.id_poste}'`;
    }
    query += ` ORDER BY date_subvention DESC`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setSubventions(response.datas);
      setFilteredSubventions(response.datas);
    } catch (error) {
      console.error('Error fetching ListSubventions', error);
    }
  }, [user.code_groupe, connectedPoste]);

  const fetchRegions = useCallback(async () => {
    let query = `SELECT btrim(nom_region) as a_region, btrim(iso_code_region) as code_region
      FROM regions
      ORDER BY nom_region`;

    try {
      const response = await envoyerRequeteApi(query, '');
      setRegions(response.datas);
    } catch (error) {
      console.error('Error fetching regions', error);
    }
  }, []);

  const fetchPostes = useCallback(async () => {
    let query = `SELECT DISTINCT id_poste, nom_poste FROM list_subventions`;

    try {
      const response = await envoyerRequeteApi(query, '');
      setPostes(response.datas);
    } catch (error) {
      console.error('Error fetching postes', error);
    }
  }, []);

  const fetchProfils = useCallback(async () => {
    let query = `SELECT id_gere, btrim(nom_profil) as nom_profil from gere_card gc `;

    try {
      const response = await envoyerRequeteApi(query, '');
      setProfils(response.datas);
    } catch (error) {
      console.error('Error fetching profils', error);
    }
  }, []);
  
  const fetchTicketsRemis = useCallback(async () => {
    try {
      const query =`SELECT coalesce(sum(ticket_dispo),0) as tickets_remis
        FROM list_subventions 
        where  id_poste = ${user.id_sgroupe}`;

      console.log("Requête pour sélectionner le nombre de tickets reçus:", query);
      const response = await envoyerRequeteApi(query, '');
      setTicketsRemis(response.datas[0].tickets_remis);
    } catch (error) {
      console.error('Error fetching tickets dispo', error);
    }
  }, [user.id_sgroupe]);

  const fetchTicketsDispo = useCallback(async () => {
    try {
      const query = `SELECT * from get_ticketdispo_poste(${connectedPoste?.id_poste || 0}) AS tickets_dispo;`;
      console.log("Requête pour sélectionner le nombre de tickets remis:", query);
      const response = await envoyerRequeteApi(query, '');

      setTicketsDispo(response.datas[0].tickets_dispo);
    } catch (error) {
      console.error('Error fetching tickets dispo', error);
    }
  }, [connectedPoste]);

  useEffect(() => {
    fetchSubventions();
    fetchRegions();
    fetchPostes();
    fetchProfils();
    fetchTicketsDispo(); // Récupérer le nombre de tickets disponibles
    fetchTicketsRemis();
  }, [fetchSubventions, fetchRegions, fetchPostes, fetchProfils, fetchTicketsDispo,fetchTicketsRemis]);

  const applyFilters = useCallback(() => {
    let filtered = ListSubventions;

    if (selectedRegion) {
      filtered = filtered.filter(subvention => subvention.a_region === selectedRegion);
    }
    if (selectedPoste) {
      filtered = filtered.filter(subvention => subvention.code_poste === selectedPoste);
    }
    if (selectedProfil) {
      filtered = filtered.filter(subvention => subvention.nom_profil === selectedProfil);
    }
    if (matriculeFilter) {
      filtered = filtered.filter(subvention => subvention.matricule.includes(matriculeFilter));
    }

    setFilteredSubventions(filtered);
  }, [ListSubventions, selectedRegion, selectedPoste, selectedProfil, matriculeFilter]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastSubvention = currentPage * subventionsPerPage;
  const indexOfFirstSubvention = indexOfLastSubvention - subventionsPerPage;
  const currentSubventions = filteredSubventions.slice(indexOfFirstSubvention, indexOfLastSubvention);

  const totalPages = Math.ceil(filteredSubventions.length / subventionsPerPage);
  const isUserAuthorized = user && user.code_groupe === "POSTE";
  
  return (
    <>
      <Header user={user} />
      <Container className="mt-4">
        <h2>Liste des Subventions</h2>
        <Form className="mb-4">
          <Row>
            <Col>
              <FloatingLabel controlId="regionSelect" label="Région">
                <Form.Select
                  aria-label="Région"
                  value={selectedRegion}
                  onChange={(e) => setSelectedRegion(e.target.value)}
                  disabled={user.code_groupe === 'POSTE'}
                >
                  {user.code_groupe === 'POSTE' && connectedPoste ? (
                    <option value={connectedPoste.code_region}>{connectedPoste.code_region}</option>
                  ) : (
                    <>
                      <option value="">Toutes les régions</option>
                      {regions.map((region, index) => (
                        <option key={index} value={region.code_region}>
                          {region.a_region}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="posteSelect" label="Poste">
                <Form.Select
                  aria-label="Poste"
                  value={selectedPoste}
                  onChange={(e) => setSelectedPoste(e.target.value)}
                  disabled={user.code_groupe === 'POSTE'}
                >
                  {user.code_groupe === 'POSTE' && connectedPoste ? (
                    <option value={connectedPoste.id_poste}>{connectedPoste.nom_poste}</option>
                  ) : (
                    <>
                      <option value="">Tous les postes</option>
                      {postes.map((poste, index) => (
                        <option key={index} value={poste.id_poste}>
                          {poste.nom_poste}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="profilSelect" label="Profil">
                <Form.Select
                  aria-label="Profil"
                  value={selectedProfil}
                  onChange={(e) => setSelectedProfil(e.target.value)}
                >
                  <option value="">Tous les profils</option>
                  {profils.map((profil, index) => (
                    <option key={index} value={profil.nom_profil}>
                      {profil.nom_profil}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="matriculeFilter" label="Matricule">
                <Form.Control
                  type="text"
                  value={matriculeFilter}
                  onChange={(e) => setMatriculeFilter(e.target.value)}
                />
              </FloatingLabel>
            </Col>
          </Row>
        </Form>
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
        <div className="text-center mb-3">
          <strong>Total des tickets remis : {ticketsRemis} sur {ticketsDispo} disponibles</strong>
        </div>
      
        <button
          className="bouton-nouveau"
          onClick={handleNewSubventionClick}
          disabled={!isUserAuthorized}
        >
          Nouvelle subvention
        </button>

        {showAlert && (
          <Alert variant="warning" className="mt-2">
            Veuillez changer votre mot de passe avant d'ajouter une nouvelle subvention.
          </Alert>
        )}
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Matricule</th>
              <th>Tickets reçus</th>
              <th>Nom Pirogue</th>
              <th>Contact</th>
              <th>Poste</th>
              <th>Volume Max</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentSubventions.map((subvention) => (
              <tr key={subvention.matricule}>
                <td>{new Date(subvention.date_subvention).toLocaleDateString()}</td>
                <td>{subvention.matricule}</td>
                <td>{subvention.ticket_dispo}</td>
                <td>{subvention.nom_pirogue}</td>
                <td>{subvention.telephone}</td>
                <td>{subvention.nom_poste}</td>
                <td>{subvention.volume}</td>
                <td>
                  <Button variant="info">Voir</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <AddSubvention
        show={showAddSubvention}
        onHide={() => setShowAddSubvention(false)}
        refreshSubventions={fetchSubventions}
        fetchTicketsDispo={fetchTicketsDispo} // Passer la fonction pour mettre à jour les tickets disponibles
        ticketsDispo={ticketsDispo} // Passer le nombre de tickets disponibles
      />
    </>
  );
};

export default ListSubventions;
