import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col xs={12} md={4} className="text-left">
            <a href="/about">A propos</a>
          </Col>
          <Col xs={12} md={4} className="text-center">
            <span>© 2022 ICELABSOFT</span>
          </Col>
          <Col xs={12} md={4} className="text-right">
            <a href="/terms">Conditions G</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
