//composant/ListUtilisateurs
import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Toast, Spinner, Pagination } from 'react-bootstrap';
import { getReponseApi } from '../apis/api_backend';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';
import Header from '../Header';
import AddAgent from './AddAgent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie, faKeyboard, faUsers } from '@fortawesome/free-solid-svg-icons';
import '../styles/ListUtilisateurs.css';

const ListUtilisateurs = () => {
  const { user } = useContext(SycadContext);
  const [agents, setAgents] = useState([]);
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [groupes, setGroupes] = useState([]);
  const [selectedGroupe, setSelectedGroupe] = useState('');
  const [nomAgent, setNomAgent] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showToast, setShowToast] = useState(true);
  const [showAddAgentModal, setShowAddAgentModal] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const agentsPerPage = 150;

  // Fonction utilitaire pour récupérer les groupes d'utilisateurs
  const fetchGroupes = useCallback(async () => {
    let query = `SELECT DISTINCT btrim(gr.code_groupe) AS code_groupe 
                FROM groupes gr
                WHERE code_groupe not in ('SADMIN')
                `;

    switch (user.code_groupe) {
      case 'ADMIN':
        query += ` AND code_groupe NOT in ('STATION', 'COMMISSION', 'DOUANE', 'PETROLIER')`;
        break;
      case 'DOUANE':
        query += ` AND code_groupe = 'DOUANE'`;
        break;
      case 'PETROLIER':
        query += ` AND code_groupe IN ('PETROLIER','STATION')`;
        break;
      default:
        break;
    }
    query += ` ORDER BY code_groupe`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setGroupes(response.datas);
    } catch (error) {
      console.error('Erreur lors de la récupération des groupes:', error);
    }
  }, [user]);

  // Fonction utilitaire pour construire la requête SQL des agents
  const buildAgentQuery = useCallback(() => {
    let query = '';
    if (user) {
      switch (user.code_groupe) {
        case 'PETROLIER':
          query = `SELECT * FROM liste_agents 
                    WHERE (id_sgroupe = ${user.id_sgroupe} AND code_groupe = 'PETROLIER') 
                    OR (id_sgroupe IN (SELECT id_station FROM stations WHERE id_petrolier = ${user.id_sgroupe}))`;
          break;
        case 'DOUANE':
          query = `SELECT * FROM liste_agents WHERE code_groupe = 'DOUANE'`;
          break;
        case 'PECHE':
          query = `SELECT * FROM liste_agents WHERE code_groupe = 'PECHE'`;
          break;
        case 'ADMIN':
          query = `SELECT * FROM liste_agents WHERE code_groupe not in ('COMMISSION', 'DOUANE', 'SADMIN') `;
          break;
        case 'SADMIN':
          query = `SELECT * FROM liste_agents`;
          break;
        default:
          query = `SELECT * FROM liste_agents WHERE code_groupe LIKE '%${selectedGroupe}%'`;
          break;
      }
    }
    return query;
  }, [user, selectedGroupe]);

  // Fonction pour récupérer les agents
  const fetchAgents = useCallback(async () => {
    setShowToast(true);
    const query = buildAgentQuery();
    console.log('fetchAgents', query);
    try {
      const response = await envoyerRequeteApi(query, '');
      console.log('fetchAgents', response);
      setAgents(response.datas);
      setFilteredAgents(response.datas);
    } catch (error) {
      console.error('Erreur lors de la récupération des agents:', error);
    } finally {
      setShowToast(false);
    }
  }, [buildAgentQuery]);

  useEffect(() => {
    fetchGroupes();
    fetchAgents();
  }, [fetchGroupes, fetchAgents]);

  // Utilisation de useMemo pour appliquer les filtres
  const filteredAgentsMemo = useMemo(() => {
    let filtered = agents;
    if (selectedGroupe) {
      filtered = filtered.filter(agent => agent.code_groupe === selectedGroupe);
    }
    if (nomAgent) {
      filtered = filtered.filter(agent =>
        agent.nom_agent.toLowerCase().includes(nomAgent.toLowerCase()) ||
        agent.prenom_agent.toLowerCase().includes(nomAgent.toLowerCase())
      );
    }
    return filtered;
  }, [agents, selectedGroupe, nomAgent]);

  useEffect(() => {
    setFilteredAgents(filteredAgentsMemo);
  }, [filteredAgentsMemo]);

  const handleAddNewAgent = () => {
    setSelectedAgent(null);
    setShowAddAgentModal(true);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Fonction pour réinitialiser le mot de passe d'un agent
  const handleReinitPassword = async (id_agent, plogin_agent) => {
    const confirmation = window.confirm(`Êtes-vous sûr de vouloir réinitialiser le mot de passe du compte ${plogin_agent}?`);
    if (confirmation) {
      try {
        const query = `SELECT * FROM reinit_password(${id_agent}, '${user.login_agent}')`;
        const response = await envoyerRequeteApi(query, '');
        if (response && response.datas && response.datas[0]) {
          alert(`Réinitialisation : ${response.datas[0].reinit_password}`);
        }
      } catch (error) {
        console.error('Erreur lors de la réinitialisation du mot de passe', error);
      }
    }
  };

  // Fonction pour supprimer un agent avec confirmation
 // Fonction pour supprimer un agent avec confirmation
const handleDelete = async (id_agent, puser) => {
  const confirmation = window.confirm('Êtes-vous sûr de vouloir supprimer cet agent ?');
  if (confirmation) {
      try {
          const response = await getReponseApi(`/api/agents/desactive/${id_agent}/${puser}`, 'DELETE');

          // Vérification si la réponse contient une erreur
          if (response.error) {
              if (response.status === 404) {
                  alert('Agent non trouvé. Veuillez vérifier l\'ID de l\'agent.');
              } else {
                  alert(`Erreur: ${response.message}`);
              }
              return;
          }

          // Suppression réussie
          alert(response.message || 'Agent désactivé avec succès.');
          fetchAgents();
      } catch (error) {
          console.error(`Erreur lors de la désactivation de l'agent avec l'ID ${id_agent}:`, error);
          alert('Erreur serveur lors de la désactivation de l\'agent. Veuillez réessayer.');
      }
  }
};


  const handleBlock = (id_agent) => {
    const confirmation = window.confirm('Êtes-vous sûr de vouloir bloquer cet agent ?');
    if (confirmation) {
      console.log(`Bloquer l'agent avec l'ID: ${id_agent}`);
      // Logique pour bloquer l'agent ici
    }
  };

  const handleEdit = (agent) => {
    setSelectedAgent(agent);
    setShowAddAgentModal(true);
  };

  const handleSave = () => {
    setShowAddAgentModal(false);
    fetchAgents();
  };

  const handleRefresh = () => {
    fetchAgents();
  };

  const indexOfLastAgent = currentPage * agentsPerPage;
  const indexOfFirstAgent = indexOfLastAgent - agentsPerPage;
  const currentAgents = useMemo(() => filteredAgents.slice(indexOfFirstAgent, indexOfLastAgent), [filteredAgents, indexOfFirstAgent, indexOfLastAgent]);

  const totalPages = Math.ceil(filteredAgents.length / agentsPerPage);

  const disableNewAgentButton = user && ['PECHEUR', 'POSTE', 'STATION', 'COMMISSION', 'PECHE'].includes(user.code_groupe);

  return (
    <>
      <Header user={user} />
      <div className="container-list-utilisateurs">
        <h2>Liste des utilisateurs</h2>
       
        <div className="form-container">
          <div className="form-group">
            <label htmlFor="groupeSelect">Groupe</label>
            <select
              id="groupeSelect"
              value={selectedGroupe}
              onChange={(e) => setSelectedGroupe(e.target.value)}
            >
              <option value="">Tous les groupes</option>
              {groupes.map((groupe, index) => (
                <option key={index} value={groupe.code_groupe}>
                  {groupe.code_groupe}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="nomAgentFilter">Nom de l'agent</label>
            <input
              type="text"
              id="nomAgentFilter"
              value={nomAgent}
              onChange={(e) => setNomAgent(e.target.value)}
            />
          </div>
        </div>

        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>

        <div className="text-center mb-3">
          <strong>Nombre d'agents affichés: {filteredAgents.length}</strong>
          <button
            className="bouton-nouveau"
            onClick={handleAddNewAgent}
            disabled={disableNewAgentButton}
          >
            Nouveau agent
          </button>
        </div>

        <div className="utilisateurs-container">
          {currentAgents.map((agent) => (
            <div key={agent.id_agent} className="utilisateur-card">
              <img className="utilisateur-img" src={`${process.env.PUBLIC_URL}/images/userprofil.png`} alt="User Icon" />
              <h3>{agent.nom_agent} {agent.prenom_agent}</h3>
              <p><FontAwesomeIcon icon={faUserTie} /> <strong>Profil:</strong> {agent.libelle_groupe}</p>
              <p><FontAwesomeIcon icon={faKeyboard} /> <strong>Login:</strong> {agent.login_agent}</p>
              <p><FontAwesomeIcon icon={faUsers} /> <strong>Groupe:</strong> {agent.code_groupe}</p>
              <div className="button-group">
                <button className="rounded-button btn-primary" onClick={() => handleEdit(agent)}>Editer</button>
                <button className="rounded-button btn-warning" onClick={() => handleBlock(agent.id_agent)}>Bloquer</button>
                <button className="rounded-button btn-success" onClick={() => handleReinitPassword(agent.id_agent, agent.login_agent)}>
                  Réinitialiser MDP
                </button>
                <button className="rounded-button btn-danger" onClick={() => handleDelete(agent.id_agent,user.login_agent)}>
                  Supprimer
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide style={{ position: 'absolute', top: 20, right: 20 }}>
        <Toast.Header>
          <strong className="me-auto">Chargement</strong>
        </Toast.Header>
        <Toast.Body>
          <Spinner animation="border" size="sm" /> Veuillez patienter pendant le chargement des utilisateurs.
        </Toast.Body>
      </Toast>

      <AddAgent 
        show={showAddAgentModal} 
        onHide={() => setShowAddAgentModal(false)} 
        agent={selectedAgent} 
        onSave={handleSave} 
        onRefresh={handleRefresh} 
      />
    </>
  );
};

export default ListUtilisateurs;
